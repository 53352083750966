import React, { useState } from "react";
import Navbar from "../../components/marketplace/nav/nav";
import Contact from "../../components/profile/contact-us/contact";
import CardsFooter from "../../components/marketplace/footer/footer";

const Contact2 = () => {
  return (
    <>
    <Navbar />
    <Contact />
    <CardsFooter />
    </> 
  )
}

export default Contact2;