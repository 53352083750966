import React, { useState } from 'react';
import Navbar from '../../components/marketplace/nav/nav';
import Vouchers from '../../components/profile/voucher/vouchers';
import CardsFooter from '../../components/marketplace/footer/footer';

const VoucherManagement = () => {
  return (
    <>
      <Navbar />
      <Vouchers />
      <CardsFooter />
    </>
  );
};

export default VoucherManagement;
