import { useState, useEffect } from "react";
import MobileDetect from "mobile-detect";

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const mobileCheck = new MobileDetect(window.navigator.userAgent);
      setIsMobile(!!mobileCheck.phone());
    }
  }, []);

  return isMobile;
};
