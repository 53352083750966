import React, { useRef, useEffect } from 'react';

// interface DialogProps {
//   title: string;
//   isOpen: boolean;
//   onClose: () => void;
//   children: React.ReactNode;
// }

const Dialog = ({ title, isOpen, onClose, children }) => {
  const dialogRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.showModal();
    } else {
      dialogRef.current?.close();
    }
  }, [isOpen]);

  return (
    <dialog
      ref={dialogRef}
      onClick={(e) => {
        const dialogDimensions = dialogRef.current?.getBoundingClientRect();
        if (
          dialogDimensions &&
          (e.clientX < dialogDimensions.left ||
            e.clientX > dialogDimensions.right ||
            e.clientY < dialogDimensions.top ||
            e.clientY > dialogDimensions.bottom)
        ) {
          onClose();
        }
      }}
      style={{
        padding: '2rem',
        maxWidth: '500px',
        width: '90%',
        borderRadius: '8px',
        border: '1px solid #ccc',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        margin: 0,
        backgroundColor: 'white',
      }}
    >
      <style>
        {`
          dialog::backdrop {
            background-color: rgba(0, 0, 0, 0.5);
            backdrop-filter: blur(1.5px);
          }
        `}
      </style>
      <div style={{ position: 'relative' }}>
        <h3 style={{ marginTop: 0 }}>{title}</h3>
        <button
          onClick={onClose}
          style={{
            position: 'absolute',
            right: '-1rem',
            top: '-1rem',
            border: 'none',
            background: 'none',
            fontSize: '1.5rem',
            cursor: 'pointer',
          }}
        >
          ×
        </button>
        <div style={{ whiteSpace: 'pre-wrap', marginTop: '1rem' }}>{children}</div>
      </div>
    </dialog>
  );
};

export default Dialog;
