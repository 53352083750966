import React from "react";
import styles from "./HeroSection.module.css";

const HeroSection = () => {
  return (
    <div className={styles.heroWalletContainer}>
      <div className={styles.banner}>
        <div className={styles.bannerContent}>
          <div className={styles.textContent}>
            <h1 className={styles.titleheroshop}>
              Give <span className={styles.highlight}>More</span>, Spend{" "}
              <span className={styles.highlight}>Less!</span>
            </h1>
            <p className={styles.descriptionheroshop}>
              Enjoy exclusive discounts and referral rewards with every
              purchase— Lipaworld makes supporting family both easy and
              affordable.
            </p>
            {/* <button className={styles.tryNowButton}>Try Now</button> */}
          </div>
        </div>
      </div>
      <div className={styles.walletCardContainer}>
        <div className={styles.walletCard}>
          <div className={styles.walletHeader}>
            <div className={styles.walletIcon}>
              <img src="/img/shop/wallet-3.svg" alt="Wallet Icon" />
            </div>
            <h3 className={styles.walletTitle}>Your Wallet</h3>
            <button className={styles.topUpButton}>
              Top Up <img src="/img/shop/money-add.png" alt="Top Up Icon" />
            </button>
          </div>
          <div className={styles.walletBalance}></div>
          <a href="#" className={styles.transactionHistory}>
            Transaction history{" "}
            <img src="/img/shop/arrow-right.png" alt="Arrow Icon" />
          </a>
        </div>
        <div className={styles.blurredOverlay}>
          <img
            src="/img/shop/wallet-3.svg"
            alt="Coming Soon"
            className={styles.comingSoonImage}
          />
          <span className={styles.comingSoonText1}>Your wallet</span>
          <span className={styles.comingSoonText}>Coming Soon</span>
          <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSfV3yVqbi-mTCriuNTjCQRGh4Qt_hYgDdj5kAXGxbABN0s-xQ/viewform"
              target="_blank"
              rel="noopener noreferrer"
              className="mt-4 inline-block px-6 py-2 border border-green-600 rounded-lg text-green-600 font-semibold hover:bg-green-50 transition duration-300"
            >
              Join the waitlist
            </a>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
