import React, { useReducer, useContext, createContext } from "react";

// Create the merchant state context
const MerchantsStateContext = createContext(null);

// Create the merchant dispatch context
const MerchantsDispatchContext = createContext(() => null);

// Define the reducer function for the merchant context
const reducer = (state, action) => {
  switch (action.type) {
    case "SET_MERCHANTS":
      return { ...state, merchants: action.payload };
    case "ADD_MERCHANT":
      return {
        ...state,
        merchants: [...state.merchants, action.payload],
      };
    case "REMOVE_MERCHANT":
      return {
        ...state,
        merchants: state.merchants.filter(
          (merchant) => merchant.id !== action.payload
        ),
      };
    default:
      throw new Error(`Unknown action: ${JSON.stringify(action)}`);
  }
};

// Create a custom provider component that wraps the children with the merchant context
export const MerchantsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    merchants: [],
  });

  return (
    <MerchantsDispatchContext.Provider value={dispatch}>
      <MerchantsStateContext.Provider value={state}>
        {children}
      </MerchantsStateContext.Provider>
    </MerchantsDispatchContext.Provider>
  );
};

// Export a custom hook that returns the merchant data from the context
export const useMerchants = () => useContext(MerchantsStateContext);

// Export a custom hook that returns the dispatch function from the context
export const useDispatchMerchants = () => useContext(MerchantsDispatchContext);
