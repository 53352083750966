import React, { useState, useEffect } from "react";
import { Eye, EyeOff } from "lucide-react";
import styles from "../styles/signup.module.css";
import styles2 from "../styles/emailverification.module.css";
import { useNavigate, useLocation, Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

const NewPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmationCode: "",
  });
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Get email from navigation state
    if (location.state?.email) {
      setFormData((prev) => ({
        ...prev,
        email: location.state.email,
      }));
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email) {
      toast.error("Please enter your email");
      return;
    }

    if (!formData.password) {
      toast.error("Please enter a new password");
      return;
    }

    if (!formData.confirmationCode) {
      toast.error("Please enter the confirmation code sent to your email");
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_USERS_URL}/confirm-password`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: formData.email,
            password: formData.password,
            confirmationCode: formData.confirmationCode,
          }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || data.message || "Password reset failed");
      }

      toast.success("Password reset successful!");
      navigate("/reset-confirmation");
    } catch (error) {
      const errorMessage =
        error.name === "TypeError"
          ? "Unable to connect to the server. Please check your internet connection."
          : error.message;

      toast.error(errorMessage);
      console.error("Password reset error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/signin");
  };

  return (
    <div className={styles.container}>
      <Toaster
        position="bottom-left"
        toastOptions={{
          duration: 5000,
          style: {
            background: "#333",
            color: "#fff",
          },
        }}
      />

      <div className={styles.formWrapper}>
        <div className={styles.logoContainer}>
          <Link to="/">
            <img
              src="/img/auth/logo-black2.png"
              alt="Lipaworld logo"
              className={styles.logo}
            />
          </Link>
        </div>

        <div className={styles.welcomeText}>
          <h1>Enter New Password</h1>
          <p>
            Please enter the confirmation code sent to your email and your new
            password
          </p>
        </div>

        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.inputGroup}>
            <label>Your Email</label>
            <input
              type="text"
              placeholder="Enter email"
              value={formData.email}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  email: e.target.value,
                }))
              }
              required
            />
          </div>

          <div className={styles.inputGroup}>
            <label>Confirmation Code</label>
            <input
              type="text"
              placeholder="Enter confirmation code"
              value={formData.confirmationCode}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  confirmationCode: e.target.value,
                }))
              }
              required
            />
          </div>

          <div className={styles.inputGroup}>
            <label>New Password</label>
            <div className={styles.passwordInput}>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter your new password"
                value={formData.password}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    password: e.target.value,
                  }))
                }
                required
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className={styles.passwordToggle}
              >
                {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </button>
            </div>
            <p className={styles.passwordHint}>
              Password must be at least 8 characters long and contain uppercase,
              lowercase, numbers and special characters
            </p>
          </div>

          <div className={styles2.buttonContainer}>
            <button
              type="button"
              className={styles2.backButton}
              onClick={handleBack}
              disabled={loading}
            >
              ← Back
            </button>
            <button
              type="submit"
              className={styles2.verifyButton}
              disabled={loading}
            >
              {loading ? "Resetting..." : "Reset Password"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewPassword;
