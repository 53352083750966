import React, { useEffect, useState } from 'react';
// import { FormControl, TextField } from "@mui/material";
// import styles from "./RedemptionAmount.module.scss";

const RedemptionAmount = ({ redemptionInputValue, setRedemptionInputValue, voucher }) => {
  const [inputValue, setInputValue] = useState((voucher?.minimumAmount ?? '0.00').toString());

  useEffect(() => {
    if (voucher?.minimumAmount) {
      setInputValue(voucher.minimumAmount.toString());
      setRedemptionInputValue(voucher.minimumAmount);
    }
  }, [voucher]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);

    const floatValue = parseFloat(newValue);
    if (!isNaN(floatValue)) {
      const twoDecimalCeil = Math.ceil(floatValue * 100) / 100;
      setRedemptionInputValue(twoDecimalCeil);
    } else if (newValue === '') {
      setRedemptionInputValue(0);
    }
  };

  return <input id="outlined-text" type="text" size="small" value={inputValue} onChange={handleInputChange} />;
};

export default RedemptionAmount;
