import React, { useState } from 'react';
// import { Eye, EyeOff } from 'lucide-react';
// import { toast } from 'react-toastify';
import Sidebar from '../sideBar';
import styles from './security.module.css';

const SecuritySettings = () => {
  // const [showPassword, setShowPassword] = useState(false);

  // const verify = async () => {
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_API_USERS_URL}/verify-email?userId=${user.id}`, {
  //       // i'm not sure what the API is to send links to email so i'll need it here
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'X-Api-Key': process.env.REACT_APP_API_KEY,
  //       },
  //     });

  //     if (response.ok) {
  //       toast.success('Check your email for the next step.');
  //     }
  //   } catch (error) {
  //     toast.error('Error sending email');
  //   }
  // };

  return (
    <div className={styles.securityContainer}>
      {/* <h1 className={styles.securityTitle}>Security Settings</h1> */}

      {/* KYC Verification Section */}
      <div className={styles.securityCard}>
        <h className={styles.cardTitle}>KYC Verification</h>

        {/* Basic Tier */}
        <div className={styles.tierSection}>
          <div className={styles.tierHeader}>
            <div className={styles.tierTitleGroup}>
              <h3 className={styles.tierTitle}>Basic (No KYC)</h3>
              <span className={styles.tierBadge}>Tier 1</span>
            </div>
          </div>
          {/* <p className={styles.tierSubtitle}>No verification required.</p> */}
          <ul className={styles.tierList}>
            <li>No Transactions Allowed.</li>
            {/* <li>View recent transactions (e.g., last 3 transactions only).</li> */}
            {/* <li>Basic customer support.</li>
                        <li>Pay-as-you-go model (no stored funds).</li> */}
          </ul>
        </div>

        {/* Verified Tier */}
        <div className={styles.tierSection}>
          <div className={styles.tierHeader}>
            <div className={styles.tierTitleGroup}>
              <h3 className={styles.tierTitle}>Verified (With KYC)</h3>
              <span className={styles.tierBadge2}>Tier 2</span>
            </div>
            <div className={styles.tierActions}>
              <span className={styles.unverifiedBadge}>Unverified</span>
              {/* <button className={styles.verifyButton} onClick={verify}>
                Verify KYC
              </button> */}
            </div>
          </div>
          <p className={styles.tierSubtitle}>Requires full KYC Verification</p>
          <ul className={styles.tierList}>
            <li>Increased transaction cap (e.g., $5,000).</li>
            <li>Exclusive access to discounts and seasonal promotions.</li>
            <li>Priority customer support.</li>
            <li>Requires basic KYC verification (Proof of Identity and Selfie Verification)</li>
            <li>
              Coming Soon: Access to Wallet; Users can store funds securely for future transactions (Requires SSN).
            </li>
          </ul>
        </div>
      </div>

      {/* Password Settings Section */}
      {/* <div className={styles.securityCard}>
                <h className={styles.cardTitle}>Password Settings</h>
                <div className={styles.passwordSection}>
                    <div className={styles.passwordGroup}>
                        <label>Old Password</label>
                        <div className={styles.passwordInput}>
                            <input type="password" placeholder="Enter your old password" />
                            <button className={styles.togglePassword} onClick={() => setShowPassword(!showPassword)}>{showPassword ? <EyeOff size={20} /> : <Eye size={20} />}</button>
                        </div>
                        <span className={styles.passwordHint}>Password requirements</span>
                    </div>

                    <div className={styles.passwordGroup}>
                        <label>New Password</label>
                        <div className={styles.passwordInput}>
                            <input type="password" placeholder="Enter your new password" />
                            <button className={styles.togglePassword} onClick={() => setShowPassword(!showPassword)}>{showPassword ? <EyeOff size={20} /> : <Eye size={20} />}</button>
                        </div>
                        <span className={styles.passwordHint}>Password requirements</span>
                    </div>
                </div>
                <button className={styles.saveButton}>Save setting</button>
            </div> */}

      {/* Transaction Pin Settings Section */}
      {/* <div className={styles.securityCard}>
                <div className={styles.pinHeader}>
                    <div>
                        <h className={styles.cardTitle}>Transaction Pin Settings</h>
                        <p className={styles.pinSubtitle}>Set a pin for your wallet transactions</p>
                    </div>
                    <button className={styles.verifyButton}>Set transaction pin</button>
                </div>
            </div> */}
    </div>
  );
};

const Security = () => {
  return (
    <div className={styles.container}>
      <Sidebar />
      <main className={styles.mainContent}>
        <h1 className={styles.h1}>Security Settings</h1>
        <div className={styles.grid}>
          <SecuritySettings />
        </div>
      </main>
    </div>
  );
};

export default Security;
