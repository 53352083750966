import React, { useEffect } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import Navbar from "../components/marketplace/nav/nav";
import SecondaryNav from "../components/marketplace/secnav/secnav";
import HeroSection from "../components/marketplace/hero/hero";
import FeaturedCategories from "../components/marketplace/featured/featured";
import ProductsByCategory from "../components/marketplace/products/ProductsByCategory";
// import ProductSection from "../components/marketplace/products/products";
// import RecentSection from "../components/marketplace/recent/recent";
// import BrandsSection from "../components/marketplace/brands/brands";
import { useSelectedCountry } from "../context/SelectedCountryContext";
import { useLocation } from "react-router-dom";
import CardsFooter from "../components/marketplace/footer/footer";

const MarketPlace = () => {
  const { selectedCountry } = useSelectedCountry();
  const location = useLocation();

  const { isLoading, isError, data } = useQuery({
    queryKey: ["categories", selectedCountry.countryCode],
    queryFn: async () => {
      if (selectedCountry.countryCode) {
        const { data } = await axios.get(
          `${process.env.REACT_APP_DEALS_API_URL}/deals/categories?country=${selectedCountry.countryCode}`
        );
        return data;
      }
    },
  });

  useEffect(() => {
    if (location.state?.scrollToCategory) {
      setTimeout(() => {
        const element = document.getElementById(
          `category-${location.state.scrollToCategory}`
        );
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 500);
    }
  }, [location.state, data]);

  return (
    <>
      <Navbar />
      <SecondaryNav />
      <HeroSection />
      <FeaturedCategories />
      {/* <RecentSection /> */}
      {data &&
        selectedCountry.countryCode &&
        data.length > 0 &&
        data.map((category) => (
          <div id={`category-${category}`} key={category}>
            <ProductsByCategory
              category={category}
              countryCode={selectedCountry.countryCode}
            />
          </div>
        ))}
      <CardsFooter />
      {/* <BrandsSection /> */}
    </>
  );
};

export default MarketPlace;
