import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SearchDropdown from './SearchDropdown';
import CartIcon from '../../cartIcon/CartIcon';
import styles from './nav.module.css';

const Navbar = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const searchContainerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
        setIsSearchFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const Checkout = () => {
    navigate('/checkout');
  };

  const handleSearch = () => {
    navigate('/search');
  };

  const ComingSoon = () => {
    navigate('/coming-soon');
  };

  const VoucherManagement = () => {
    navigate('/voucher-management');
  };

  const Recipients = () => {
    navigate('/recipient-management');
  };

  const Market = () => {
    navigate('/marketplace');
  };

  const Overview = () => {
    navigate('/account-overview');
  };

  return (
    <nav className={styles.lipaworld_navbar}>
      <div className={styles.lipaworld_logo}>
        <Link to="/">
          <img src="/img/auth/logo-black2.png" alt="Lipaworld" className={styles.lipaworld_logoImage} />
        </Link>
      </div>

      <div ref={searchContainerRef} className={styles.lipaworld_searchContainer}>
        <div className={styles.lipaworld_categorySelect}>
          <span>Categories</span>
          <svg className={styles.lipaworld_chevron} width="12" height="12" viewBox="0 0 24 24">
            <path d="M7 10l5 5 5-5z" />
          </svg>
        </div>
        <input
          type="text"
          placeholder="What are you looking for?"
          className={styles.lipaworld_searchInput}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onFocus={() => setIsSearchFocused(true)}
        />
        <button className={styles.lipaworld_searchButton}>
          <img width="20" height="20" viewBox="0 0 24 24" src="/img/shop/search-normal.svg" />
        </button>
        <SearchDropdown isVisible={isSearchFocused} />
      </div>

      <div className={styles.lipaworld_navActions}>
        <button className={styles.lipaworld_navButton} onClick={Market}>
          <img
            className={styles.lipaworld_navIcon}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            src="/img/shop/home.svg"
          />
          <span>Shop</span>
        </button>

        <button className={styles.lipaworld_navButton} onClick={VoucherManagement}>
          <img
            className={styles.lipaworld_navIcon}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            src="/img/shop/wallet.svg"
          />
          <span>My Vouchers</span>
        </button>

        <button className={styles.lipaworld_navButton} onClick={Recipients}>
          <img
            className={styles.lipaworld_navIcon}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            src="/img/shop/heart.svg"
          />
          <span>Recipients</span>
        </button>

        <button className={styles.lipaworld_navButton} onClick={Overview}>
          <img
            className={styles.lipaworld_navIcon}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            src="/img/shop/profile-circle.svg"
          />
          <span>Profile</span>
        </button>

        <CartIcon />
      </div>

      <div className={styles.lipaworld_searchIconContainer}>
        <button className={`${styles.lipaworld_navButton} ${styles.lipaworld_searchIcon}`} onClick={handleSearch}>
          <img
            className={styles.lipaworld_navIcon}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            src="/img/shop/search-normal.svg"
          />
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
