import React, { useState, useRef, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate, Link } from "react-router-dom";
import styles from "../styles/emailverification.module.css";

const EmailVerification = () => {
  const { authState, updateAuthState, verifyEmail } = useAuth();
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [isValid, setIsValid] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const inputRefs = useRef([]);
  const navigate = useNavigate();
  // const location = useLocation();

  useEffect(() => {
    if (!authState.email) {
      navigate("/signup");
    }
  }, [location, navigate]);

  const handleOtpChange = (index, e) => {
    const value = e.target.value;
    if (!/^\d*$/.test(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    setIsValid(null);

    if (value !== "" && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace") {
      if (otp[index] === "" && index > 0) {
        inputRefs.current[index - 1].focus();
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
      }
    }
  };

  const handleVerifyCode = async () => {
    const enteredOtp = otp.join("");
    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_USERS_URL}/confirm-signup`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: authState.email,
            confirmationCode: enteredOtp,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        verifyEmail();
        navigate("/phonenumber");
      } else {
        setIsValid(false);
        console.error("Verification failed:", data.message);
      }
    } catch (error) {
      setIsValid(false);
      console.error("Verification error:", error);
    }
  };

  const handleBack = () => {
    navigate("/signup");
  };

  const handleResendCode = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_USERS_URL}/resend-code`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: authState.email,
          }),
        }
      );

      if (response.ok) {
        // Clear existing OTP inputs
        setOtp(["", "", "", "", "", ""]);
        setIsValid(null);
        // Success Message ?
      } else {
        // Handle error
        console.error("Failed to resend OTP");
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.formWrapper}>
        <div className={styles.logoContainer}>
          <Link to="/">
            <img
              src="/img/auth/logo-black2.png"
              alt="Lipaworld logo"
              className={styles.logo}
            />
          </Link>
        </div>

        {/* Verify Email */}
        <div className={styles.verifyEmail}>
          <h1>Verify your email</h1>
          <p>
            We sent a verification code to {authState.email}. To verify your
            email address, please check your inbox and enter the code below.
          </p>

          {/* OTP Input */}
          <div className={styles.otpInput}>
            {Array.from({ length: 6 }).map((_, index) => (
              <input
                key={index}
                ref={(el) => (inputRefs.current[index] = el)}
                type="text"
                maxLength={1}
                value={otp[index]}
                onChange={(e) => handleOtpChange(index, e)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                className={`${styles.otpDigit} ${
                  isValid === true
                    ? styles.otpValid
                    : isValid === false
                    ? styles.otpInvalid
                    : ""
                }`}
              />
            ))}
          </div>

          <div className={styles.buttonContainer}>
            <button className={styles.backButton} onClick={handleBack}>
              ← Back
            </button>
            <button
              type="submit"
              className={styles.verifyButton}
              onClick={handleVerifyCode}
              disabled={loading}
            >
              {loading ? "Verifying..." : "Verify Code"}
            </button>
          </div>

          {/* Resend Email */}
          <p className={styles.resendEmail}>
            Didn't receive an email?{" "}
            <a
              className={styles.resendLink}
              onClick={(e) => {
                e.preventDefault();
                handleResendCode();
              }}
            >
              Try again
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
