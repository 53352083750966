import React, { useState } from 'react';
import styles from './EditRecipientModal.module.css';
import { useAuth } from '../../../context';
import axios from 'axios';
import { parsePhoneNumber } from 'libphonenumber-js';
import 'react-phone-number-input/style.css';

const EditRecipientModal = ({ onClose }) => {
  const [loading, setLoading] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const { authState } = useAuth(); 

  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    mobileNumber: "",
    mobileCountryCode: "", 
    electricityMeterNumber: "",
    studentNumber: "",
    cowryPhoneNumber: "",
    country: "US",
    senderId: authState?.id,
  });
  const handleSubmit = async (e) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    if (!token || !authState?.id) {
      console.warn('Authentication required');
      setLoading(false);
      return;

    };
    try {

      let country = "US"; 
      if (formData.mobileNumber) {
        try {
          const phoneNumber = parsePhoneNumber(formData.mobileNumber);
          country = phoneNumber.country || "NG";
        } catch (parseError) {
          console.warn("Could not parse phone number:", parseError);
        }
      }

      const submissionData = {
        ...formData,
        countryCode: formData.mobileCountryCode,
        country: country
      };
       await axios.post(`https://z3j7vwu4pa.execute-api.us-east-1.amazonaws.com/beta/recipients?id=${recipients.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
          body: JSON.stringify(submissionData)
        }
      );
      setRecipients(response.data.recipients);
    } catch (err) {
      setError('Failed to edit recipient');
      setLoading(false);
    }
  }

  const handlePhoneChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      mobileNumber: value || "",
      mobileCountryCode: value ? `+${value.replace(/\D/g, '')}` : "",
    }));
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <h2>Edit Recipient</h2>
          <button 
            className={styles.closeButton}
            onClick={onClose}
          >
            ×
          </button>
        </div>

        {/* <p className={styles.modalSubtext}>
          Complete the fields to create a recipient
        </p> */}

        <form className={styles.createForm}>
          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label>First Name</label>
              <input
                type="text"
                value={formData.firstName}
                onChange={(e) => setFormData({
                  ...formData,
                  firstName: e.target.value
                })}
              />
            </div>

            <div className={styles.formGroup}>
              <label>Last Name</label>
              <input
                type="text"
                placeholder="Placeholder"
                value={formData.lastName}
                onChange={(e) => setFormData({
                  ...formData,
                  lastName: e.target.value
                })}
              />
            </div>
          </div>

          <div className={styles.formGroup}>
            <label>Phone Number</label>
            <PhoneInput
              country="US"
              value={formData.mobileNumber}
              onChange={handlePhoneChange}
              placeholder="Enter phone number"
              required
            />
          </div>

          <div className={styles.formGroup}>
            <label>Meter number</label>
            <input
              type="text"
              placeholder="Enter recipient meter number"
              value={formData.meterNumber}
              onChange={(e) => setFormData({
                ...formData,
                meterNumber: e.target.value
              })}
            />
          </div>

          <div className={styles.formGroup}>
            <label>Student number</label>
            <input
              type="text"
              placeholder="Enter recipient student number"
              value={formData.studentNumber}
              onChange={(e) => setFormData({
                ...formData,
                studentNumber: e.target.value
              })}
            />
          </div>

          <div className={styles.formGroup}>
            <label>Cowry phone number (Nigeria only)</label>
            <input
              type="text"
              placeholder="Enter recipient Cowry phone number"
              value={formData.studentNumber}
              onChange={(e) => setFormData({
                ...formData,
                cowryNumber: e.target.value
              })}
            />
          </div>

          <div className={styles.modalActions}>
            <button 
              type="button" 
              className={styles.cancelButton}
              onClick={onClose}
            >
              Cancel
            </button>
            <button 
              type="submit" 
              className={styles.createButton}
              onClick={handleSubmit}
            >
              Edit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditRecipientModal;