import React, { createContext, useContext, useState } from "react";

const AuthContext = createContext(null);

const blankUser = {
  id: "",
  email: "",
  firstName: "",
  lastName: "",
  tempPassword: "",
  phoneNumber: "",
  userType: "",
  isAuthenticated: false,
  isVerified: false,
  indexNumber: null,
  createdAt: "",
  updatedAt: "",
  country: ""
};

export const AuthProvider = ({ children }) => {
  // Initialize auth state from localStorage or use blank user
  const [authState, setAuthState] = useState(() => {
    const token = localStorage.getItem('token');
    const storedUser = localStorage.getItem('user');
    
    if (token && storedUser) {
      const user = JSON.parse(storedUser);
      return { 
        ...user, 
        isAuthenticated: true 
      };
    }
    
    return blankUser;
  });

  const signUp = (userData, isGoogleSignup = false) => {
    const authData = {
      ...blankUser,
      ...userData,
      isAuthenticated: true,
      isVerified: isGoogleSignup ? true : (userData.isVerified || false)
    };

    setAuthState(authData);
    localStorage.setItem('user', JSON.stringify(authData));
    
    if (!isGoogleSignup) {
      localStorage.setItem('token', 'temp-token');
    }

    return authData;
  };

  const verifyEmail = () => {
    const updatedAuth = { 
      ...authState, 
      isVerified: true 
    };
    
    setAuthState(updatedAuth);
    localStorage.setItem('user', JSON.stringify(updatedAuth));
  };

  const addPhoneNumber = (phoneNumber) => {
    const updatedAuth = { 
      ...authState, 
      phoneNumber, 
      isAuthenticated: true 
    };
    
    setAuthState(updatedAuth);
    localStorage.setItem('user', JSON.stringify(updatedAuth));
  };

  const updateAuthState = (updates) => {
    setAuthState((prev) => {
      const newState = { ...prev, ...updates };
      localStorage.setItem('user', JSON.stringify(newState));
      return newState;
    });
  };

  const resetAuthState = () => {
    setAuthState(blankUser);
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  };

  return (
    <AuthContext.Provider 
      value={{ 
        authState, 
        signUp, 
        verifyEmail, 
        addPhoneNumber, 
        updateAuthState, 
        resetAuthState 
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};