import React from "react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

function Statistics() {
  const stats = [
    {
      value: 200000,
      displayValue: "+200k",
      label: "Accepted across Informal traders",
    },
    { value: 200, displayValue: "200", label: "Customers impacted this year" },
    {
      value: 95,
      displayValue: "95%",
      label: "Satisfaction rate",
      isPercentage: true,
    },
    {
      value: 2100,
      displayValue: "2.1k",
      label: "Vouchers and Top-ups available",
    },
  ];

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <section ref={ref} className="py-12 px-6 text-black statsec">
      <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
        {stats.map((stat, index) => (
          <div key={index} className="text-center statext">
            <h3 className="text-4xl font-bold mb-2">
              {inView ? (
                <CountUp
                  end={stat.value}
                  duration={4}
                  formattingFn={(value) => {
                    if (stat.displayValue && value === stat.value)
                      return stat.displayValue;
                    return stat.isPercentage ? `${value}%` : value;
                  }}
                />
              ) : (
                <span>{stat.displayValue || stat.value}</span>
              )}
            </h3>
            <p>{stat.label}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Statistics;
