import React from "react";
import Navbar from "../../components/marketplace/nav/nav";
import RecipientManagementComponent from "../../components/profile/recipient/recipient";
import CardsFooter from "../../components/marketplace/footer/footer";

const RecipientManagement = () => {
  return (
    <>
      <Navbar />
      <RecipientManagementComponent />
      <CardsFooter />
    </>
  );
};

export default RecipientManagement;
