import React, { useState } from 'react';
import { ChevronDown, ChevronUp, X, Minus, Plus } from 'lucide-react';
import CartButtons from '../../cartButtons/CartButtons';
import RedemptionAmount from './RedemptionAmount';
import IncludeProcessingFee from './IncludeProcessingFee';
import styles from './VoucherDetails.module.css';

// Accordion component for reusable expandable sections
const Accordion = ({ title, content, isExpanded, onToggle }) => (
  <>
    <button onClick={onToggle} className={styles.accordionButton}>
      <span>{title}</span>
      {isExpanded ? <ChevronUp /> : <ChevronDown />}
    </button>
    {isExpanded && <div className={styles.accordionContent}>{content}</div>}
  </>
);

// Component for the left column content
const ProductInfo = ({
  product,
  isDescriptionExpanded,
  setIsDescriptionExpanded,
  isRedemptionExpanded,
  setIsRedemptionExpanded,
}) => (
  <div className={styles.leftColumn}>
    <div className={styles.imageContainer}>
      <img src={product.voucherImageUrl} alt={product.voucherName} />
    </div>

    <h2 className={styles.title}>{product.voucherName}</h2>

    <div className={styles.accordionContainer}>
      <Accordion
        title="Description"
        content={<p>{product.voucherDescription}</p>}
        isExpanded={isDescriptionExpanded}
        onToggle={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
      />

      {product.redemptionInstructions && (
        <Accordion
          title="Redemption Instructions"
          content={<p>{product.redemptionInstructions}</p>}
          isExpanded={isRedemptionExpanded}
          onToggle={() => setIsRedemptionExpanded(!isRedemptionExpanded)}
        />
      )}
    </div>
  </div>
);

// Component for amount selection
const AmountSelection = ({ product, redemptionInputValue, setRedemptionInputValue }) => {
  if (!product.customAmount) {
    return (
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <h3>Select Amount</h3>
        </div>
        <div className={styles.amountGrid}>
          {product.redemptionValues.map((amount) => (
            <button
              key={amount}
              onClick={() => setRedemptionInputValue(amount)}
              className={`${styles.amountButton} ${redemptionInputValue === amount ? styles.amountButtonActive : ''}`}
            >
              {`${product.redemptionCurrency} ${(amount / 100).toFixed(2)}`}
            </button>
          ))}
        </div>
      </div>
    );
  }

  if (
    product.customAmount &&
    (product.stockQuantity !== 0 || product.categories.includes('Education')) &&
    product.status !== 'Coming Soon' &&
    product.redemptionType !== 'DONATION'
  ) {
    return (
      <div className={styles.customAmount}>
        <p className={styles.sectionLabel}>Custom Amount</p>
        <div className={styles.customAmountInput}>
          <div className={styles.currencySelect}>
            <img src={`/flags/${product.countryCode}.svg`} alt={`${product.countryCode} flag`} />
            <span>{product.redemptionCurrency}</span>
          </div>
          <RedemptionAmount
            voucher={product}
            redemptionInputValue={redemptionInputValue}
            setRedemptionInputValue={setRedemptionInputValue}
          />
        </div>
      </div>
    );
  }

  return null;
};

// Component for quantity controls
const QuantityControls = ({ quantity, setQuantity }) => (
  <div className={styles.section}>
    <div className={styles.sectionHeader}>
      <h3>Select Quantity</h3>
    </div>
    <div className={styles.quantityControls}>
      <button onClick={() => quantity > 1 && setQuantity(quantity - 1)} className={styles.quantityButton}>
        <Minus />
      </button>
      <input type="text" value={quantity} readOnly className={styles.quantityInput} />
      <button onClick={() => setQuantity(quantity + 1)} className={styles.quantityButton}>
        <Plus />
      </button>
    </div>
  </div>
);

const VoucherDetails = ({ onClose, product, redemptionInputValue, setRedemptionInputValue }) => {
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(true);
  const [isRedemptionExpanded, setIsRedemptionExpanded] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [includeProcessingFee, setIncludeProcessingFee] = useState(false);

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  if (!product) return null;

  return (
    <div className={styles.overlay} onClick={handleOverlayClick}>
      <div className={styles.modal}>
        <div className={styles.modalContent}>
          <div className={styles.closeButton}>
            <button onClick={onClose}>
              <X />
            </button>
          </div>

          <div className={styles.gridContainer}>
            <ProductInfo
              product={product}
              isDescriptionExpanded={isDescriptionExpanded}
              setIsDescriptionExpanded={setIsDescriptionExpanded}
              isRedemptionExpanded={isRedemptionExpanded}
              setIsRedemptionExpanded={setIsRedemptionExpanded}
            />

            <div className={styles.rightColumn}>
              <AmountSelection
                product={product}
                redemptionInputValue={redemptionInputValue}
                setRedemptionInputValue={setRedemptionInputValue}
              />

              <QuantityControls quantity={quantity} setQuantity={setQuantity} />

              <div className={styles.footer}>
                {product.redemptionType === 'DONATION' && (
                  <IncludeProcessingFee
                    includeProcessingFee={includeProcessingFee}
                    setIncludeProcessingFee={setIncludeProcessingFee}
                    institution={product.merchantName}
                  />
                )}

                <div className={styles.total}>
                  <span>Price</span>
                  <span className={styles.totalAmount}>
                    {product.usdAmount
                      ? `USD ${(product.usdAmount * quantity).toFixed(2)}`
                      : `${product.redemptionCurrency} ${(redemptionInputValue * quantity).toFixed(2)}`}
                  </span>
                </div>

                <CartButtons
                  deal={product}
                  quantity={quantity}
                  onClose={onClose}
                  redemptionInputValue={redemptionInputValue}
                  includeProcessingFee={includeProcessingFee}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoucherDetails;
