import React, { useState } from 'react';
import Navbar from '../../components/marketplace/nav/nav';
import EmptyOrdersCard from '../../components/checkout/empty/Empty';

const Empty = () => (
  <>
    <Navbar />
    <EmptyOrdersCard />
  </>
);

export default Empty;
