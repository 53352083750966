import React, { useState } from "react";
import Navbar from "../../components/marketplace/nav/nav";
import ProfileInfo from "../../components/profile/profile-info/profileInfo";
import CardsFooter from "../../components/marketplace/footer/footer";

const ProfileInformation = () => {
  return (
    <>
    <Navbar />
    <ProfileInfo />
    <CardsFooter />
    </> 
  )
}

export default ProfileInformation;