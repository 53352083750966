import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './coming.module.css';

const ComingSoon = () => {

    const navigate = useNavigate();

    const handleShop = () => {
      navigate('/marketplace')
    };
  return (
    <>
    <button className={styles.lipaworld_backButton} onClick={handleShop}>
          <svg width="20" height="20" viewBox="0 0 24 24">
              <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
          </svg>
          <span className={styles.backText}>Back to Shop</span>
      </button><div className={styles.emptyOrdersContainer}>
              <div className={styles.emptyOrdersCard}>
                  <div className={styles.cartIconWrapper}>
                      <div className={styles.cartIcon}>
                          <img src='/img/shop/empty.svg' />
                      </div>
                  </div>
                  <h2 className={styles.emptyOrdersTitle}>This page isn't live yet!</h2>
                  <p className={styles.emptyOrdersDescription}>
                      We are working hard to make it live as soon as possible and will update you when it is done!
                  </p>
                  <button className={styles.startShoppingButton} onClick={handleShop}>Back to Shop</button>
              </div>
          </div>
          </>
  );
};

export default ComingSoon;