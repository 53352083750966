import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import styles from "../styles/emailverification.module.css";

const PasswordReset = () => {
  const [otp, setOtp] = useState("");
  const [isValid, setIsValid] = useState(null);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleOtpChange = (e) => {
    const value = e.target.value;
    setOtp(value);
    setIsValid(null); // Reset validation state
  };

  const handleVerifyCode = () => {
    navigate("/new-password");
  };
  const handleBack = () => {
    navigate("/signin");
  };

  return (
    <div className={styles.container}>
      <div className={styles.formWrapper}>
        <div className={styles.logoContainer}>
          <Link to="/">
            <img
              src="/img/auth/logo-black2.png"
              alt="Lipaworld logo"
              className={styles.logo}
            />
          </Link>
        </div>

        <div className={styles.verifyEmail}>
          <h1>Password reset email has been sent</h1>
          <p>A password reset email has been sent to your email address</p>{" "}
          <br /> <br />
          <div className={styles.buttonContainer}>
            <button className={styles.backButton} onClick={handleBack}>
              ← Back
            </button>
            <button className={styles.verifyButton} onClick={handleVerifyCode}>
              Continue
            </button>
          </div>
          <p className={styles.resendEmail}>
            Didn't receive an email?{" "}
            <a href="#" className={styles.resendLink}>
              Try again
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
