import React, { useState, useEffect, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import styles from './RecipientSelect.module.css';
import EditRecipientModal from './EditRecipientModal';
import { useAuth } from '../../../context';

const RecipientSelect = ({ onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const containerRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const { authState } = useAuth();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['recipients', authState?.id],
    queryFn: async () => {
      const token = localStorage.getItem('token');
      if (!token || !authState?.id) {
        throw new Error('Authentication required');
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API_RECIPIENTS_URL}/recipients?userId=${authState.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      return response.data;
    },
    enabled: !!authState?.id,
  });

  const recipients = data?.recipients || [];
  const responseMessage = data?.message || 'No recipients found';

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleRecipientSelect = (recipient) => {
    setSelectedRecipient(recipient);
    setSearchText(`${recipient.name} ${recipient.surname} - ${recipient.mobileNumber}`);
    setIsOpen(false);
    onSelect(recipient.id, recipient);
  };

  const filteredRecipients =
    recipients?.filter((recipient) =>
      `${recipient.name} ${recipient.surname} ${recipient.mobileNumber}`
        .toLowerCase()
        .includes(searchText.toLowerCase()),
    ) || [];

  return (
    <div className={styles.selectContainer} ref={containerRef}>
      <input
        type="text"
        className={styles.searchInput}
        placeholder="Write recipient name or number"
        onClick={() => setIsOpen(true)}
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
      />

      {isOpen && (
        <div className={styles.dropdown}>
          {isLoading ? (
            <div className={styles.loadingState}>Loading recipients...</div>
          ) : recipients.length > 0 ? (
            recipients.map((recipient) => (
              <div key={recipient.id} className={styles.recipientItem} onClick={() => handleRecipientSelect(recipient)}>
                <div className={styles.recipientInfo}>
                  <span className={styles.recipientName}>
                    {recipient.name} {recipient.surname}
                  </span>
                  <span className={styles.recipientPhone}>{recipient.mobileNumber}</span>
                </div>
              </div>
            ))
          ) : (
            <div className={styles.noResults}>{responseMessage}</div>
          )}
        </div>
      )}

      {/* {selectedRecipient && (
        <div className={styles.editLink}>
          <a href="#" onClick={() => setShowModal(true)}>
            Edit recipient details
          </a>
        </div>
      )} */}

      {showModal && <EditRecipientModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default RecipientSelect;
