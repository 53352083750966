import React, { useState } from "react";
import { Loader2 } from "lucide-react";

const Contact = () => {
  const [activeTab, setActiveTab] = useState("individual");
  const [isLoading, setIsLoading] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const TIMEOUT_DURATION = 30000;

  const resetForm = (form) => {
    form.reset();
    setIsLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setSubmitStatus(null);

    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => {
        reject(new Error("Request timed out"));
      }, TIMEOUT_DURATION);
    });

    try {
      const formData =
        activeTab === "individual"
          ? {
              contactType: "INDIVIDUAL",
              firstName: e.target.elements[0].value,
              lastName: e.target.elements[1].value,
              emailAddress: e.target.elements[2].value,
              phoneNumber: e.target.elements[3].value,
              subject: e.target.elements[4].value,
              message: e.target.elements[5].value,
            }
          : {
              contactType: "BUSINESS",
              firstName: e.target.elements[0].value,
              lastName: e.target.elements[1].value,
              emailAddress: e.target.elements[2].value,
              companyName: e.target.elements[3].value,
              website: e.target.elements[4].value,
              productCategory: e.target.elements[5].value,
              message: e.target.elements[6].value,
            };

      const response = await Promise.race([
        fetch(`${process.env.REACT_APP_API_USERS_URL}/contact-us`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }),
        timeoutPromise,
      ]);

      if (!response.ok) {
        throw new Error("Failed to send email");
      }

      setSubmitStatus("success");
      resetForm(e.target);

      setTimeout(() => {
        setSubmitStatus(null);
      }, 5000);
    } catch (error) {
      console.error("Error submitting form:", error);
      setSubmitStatus(
        error.message === "Request timed out" ? "timeout" : "error"
      );
      setIsLoading(false);

      setTimeout(() => {
        setSubmitStatus(null);
      }, 5000);
    }
  };

  const getStatusMessage = (status) => {
    switch (status) {
      case "success":
        return "Message sent successfully! We will get back to you soon.";
      case "timeout":
        return "Request timed out. Please check your internet connection and try again.";
      case "error":
        return "Error sending message. Please try again.";
      default:
        return "";
    }
  };

  return (
    <div className="contact-section">
      <div className="contact-form" data-aos="fade-right">
        {submitStatus && (
          <div
            className={`p-4 mb-4 rounded-lg ${
              submitStatus === "success"
                ? "bg-green-50 border border-green-200 text-green-700"
                : "bg-red-50 border border-red-200 text-red-700"
            }`}
          >
            {getStatusMessage(submitStatus)}
          </div>
        )}

        <h1 className="form-title">Message Us</h1>
        <p className="form-subtitle">
          We&apos;ll get back to you within 24 Hours. For support queries please
          use the chat functionality.
        </p>
        <div className="tabs">
          <button
            type="button"
            className={`tab ${activeTab === "individual" ? "active" : ""}`}
            onClick={() => setActiveTab("individual")}
            disabled={isLoading}
          >
            For Individuals
          </button>
          <button
            type="button"
            className={`tab ${activeTab === "business" ? "active" : ""}`}
            onClick={() => setActiveTab("business")}
            disabled={isLoading}
          >
            For Businesses
          </button>
        </div>

        {activeTab === "individual" ? (
          <form className="form" onSubmit={handleSubmit}>
            <div className="form-groupname">
              <div
                className="form-group"
                style={{ flex: 1, marginRight: "8px" }}
              >
                <label>First Name</label>
                <input
                  type="text"
                  placeholder="Newman"
                  required
                  disabled={isLoading}
                />
              </div>
              <div
                className="form-group"
                style={{ flex: 1, marginLeft: "8px" }}
              >
                <label>Last Name</label>
                <input
                  type="text"
                  placeholder="Placeholder"
                  required
                  disabled={isLoading}
                />
              </div>
            </div>
            <div className="form-group">
              <label>Email Address</label>
              <input
                type="email"
                placeholder="Enter your email address"
                required
                disabled={isLoading}
              />
            </div>
            <div className="form-group">
              <label>Phone number</label>
              <input
                type="tel"
                placeholder="Enter your phone number"
                required
                disabled={isLoading}
              />
            </div>
            <div className="form-group">
              <label>Subject</label>
              <input
                type="text"
                placeholder="Enter your subject"
                required
                disabled={isLoading}
              />
            </div>
            <div className="form-group">
              <label>Message</label>
              <textarea
                placeholder="Write your message"
                required
                disabled={isLoading}
              ></textarea>
            </div>
            <button
              type="submit"
              className="submit-button flex items-center justify-center"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Sending...
                </>
              ) : (
                "Send message"
              )}
            </button>
          </form>
        ) : (
          <form className="form" onSubmit={handleSubmit}>
            <div className="contact-info-section">
              <h2 className="bizh2">Contact Information</h2>
              <div className="form-groupname" style={{ marginBottom: "13px" }}>
                <div
                  className="form-group"
                  style={{ flex: 1, marginRight: "8px" }}
                >
                  <label>First Name</label>
                  <input
                    type="text"
                    placeholder="Newman"
                    required
                    disabled={isLoading}
                  />
                </div>
                <div
                  className="form-group"
                  style={{ flex: 1, marginLeft: "8px" }}
                >
                  <label>Last Name</label>
                  <input
                    type="text"
                    placeholder="Placeholder"
                    required
                    disabled={isLoading}
                  />
                </div>
              </div>
              <div className="form-group" style={{ marginBottom: "13px" }}>
                <label>Email Address</label>
                <input
                  type="email"
                  placeholder="Enter your email address"
                  required
                  disabled={isLoading}
                />
              </div>
            </div>

            <div className="business-info-section">
              <h2 className="bizh2">Business Information</h2>
              <div className="form-groupname" style={{ marginBottom: "13px" }}>
                <div
                  className="form-group"
                  style={{ flex: 1, marginRight: "8px" }}
                >
                  <label>Company Name</label>
                  <input
                    type="text"
                    placeholder="Newman"
                    required
                    disabled={isLoading}
                  />
                </div>
                <div
                  className="form-group"
                  style={{ flex: 1, marginLeft: "8px" }}
                >
                  <label>Website</label>
                  <input
                    type="text"
                    placeholder="Placeholder"
                    required
                    disabled={isLoading}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Product Categories</label>
                <input
                  type="text"
                  placeholder="Enter your product category"
                  required
                  disabled={isLoading}
                />
              </div>
            </div>

            <div className="form-group">
              <label>Message</label>
              <textarea
                placeholder="Write your message"
                required
                disabled={isLoading}
              ></textarea>
            </div>
            <button
              type="submit"
              className="submit-button flex items-center justify-center"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Sending...
                </>
              ) : (
                "Send message"
              )}
            </button>
          </form>
        )}
      </div>

      <div className="contact-info" data-aos="fade-left">
        <div>
          <h2>Email Us</h2>
          <p>
            For general inquiries, partnership opportunities, or customer
            support.
          </p>
          <a href="mailto:support@lipaworld.com">support@lipaworld.com</a>
        </div>
        <div>
          <h2>Our Office</h2>
          <p>For any mail or correspondence.</p>
          <p>
            <span className="color">
              254 Chapman Rd, Ste 208 #12376 Newark, Delaware 19702, USA
            </span>
          </p>
        </div>
        <div>
          <h2>Follow Us On Social Media</h2>
          <p>
            Stay connected with the Lipaworld community on social media for
            updates, offers and more.
          </p>
          <div className="social-media">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://instagram.com/lipaworldafrica/"
            >
              Instagram
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/LipaworldAfrica"
            >
              X
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://web.facebook.com/profile.php?id=100093207047292"
            >
              Facebook
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://linkedin.com/company/lipaworld"
            >
              LinkedIn
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/@Lipaworldafrica"
            >
              YouTube
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
