import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './PaymentSuccessPage.module.css';

const PaymentSuccessPage = () => {
  const navigate = useNavigate();
  const transactionDetails = {
    amount: 'NGN 502,000.00',
    date: '12 Nov 2024',
    orderId: '#ID3803748',
    recipient: {
      name: 'Newman Ogbo',
      phone: '+2349038349473'
    },
    items: [
      { name: 'MTN voucher (2)', amount: 'NGN2,000' },
      { name: 'Airtel voucher (2)', amount: 'NGN2,000' }
    ],
    summary: {
      subtotal: 'NGN500,000',
      discount: 'NGN2,000',
      tax: '−',
      total: 'NGN 502,000'
    }
  };

  const handlePrintReceipt = () => {
    window.print();
  };

  const handleBackToShop = () => {
    navigate('/marketplace')
  };

  return (
    <div className={styles.successContainer}>
      <div className={styles.successCard}>
        <div className={styles.successHeader}>
          <div>
            <img src="/img/shop/success.svg" alt="Success" className={styles.successIcon} />
          </div>
          <h1 className={styles.font}>Payment Successful</h1>
          <div className={styles.amountSection}>
            <span className={styles.amountLabel}>Amount</span>
            <span className={styles.amount}>{transactionDetails.amount}</span>
          </div>
          <div className={styles.transactionInfo}>
            <span>{transactionDetails.date}</span>
            <span className={styles.separator}>|</span>
            <span>Order ID: {transactionDetails.orderId}</span>
          </div>
        </div>

        <div className={styles.detailsSection}>
          <div className={styles.deets}>
          <h2>Payment Details</h2>
          </div>
          
          
          <div className={styles.itemsList}>
            {transactionDetails.items.map((item, index) => (
              <div key={index} className={styles.item}>
                <span>{item.name}</span>
                <span>{item.amount}</span>
              </div>
            ))}
          </div>

          <div className={styles.recipientInfo}>
            <h3>Recipient</h3>
            <div className={styles.recipient}>
              <span>{transactionDetails.recipient.name}</span>
              <span>{transactionDetails.recipient.phone}</span>
            </div>
          </div>

          <div className={styles.transactionDetails}>
          <div className={styles.deets}>
          <h2>Detail Transaction</h2>
          </div>
            <div className={styles.summaryList}>
              <div className={styles.summaryItem}>
                <span>Subtotal</span>
                <span>{transactionDetails.summary.subtotal}</span>
              </div>
              <div className={styles.summaryItem}>
                <span>Discount</span>
                <span>{transactionDetails.summary.discount}</span>
              </div>
              <div className={styles.summaryItem}>
                <span>Estimated Tax</span>
                <span>{transactionDetails.summary.tax}</span>
              </div>
              <div className={`${styles.summaryItem} ${styles.total}`}>
                <span>Total</span>
                <span>{transactionDetails.summary.total}</span>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.actions}>
          <button 
            className={styles.printButton} 
            onClick={handlePrintReceipt}
          >
            <img className={styles.printIcon} src='img/shop/printer.png'/>
            Print Receipt
          </button>
          <button 
            className={styles.shopButton}
            onClick={handleBackToShop}
          >
            Back to shop
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessPage;