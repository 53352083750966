import React, { useState } from "react";
import { Link } from "react-router-dom";
import faqData from "../utils/faqs";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleQuestion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section id="faqs">
      <div className="faq-content" data-aos="fade-right">
        <Link to="#faqs">
          <div className="faq-tag">Looking for quick answers?</div>
        </Link>
        <h2 className="faq-title">Frequently asked questions</h2>
        <p className="faq-subtitle">
          These are the most common questions asked about Lipaworld
        </p>
      </div>
      <div className="faq-questions-container" data-aos="fade-left">
        <div className="faq-questions">
          {faqData.map((category, catIndex) =>
            category.questions.map((question, qIndex) => (
              <div key={`${catIndex}-${qIndex}`}>
                <div
                  className="faq-question"
                  onClick={() => toggleQuestion(`${catIndex}-${qIndex}`)}
                >
                  <div className="faq-question-summary">{question.summary}</div>
                  <img
                    src="/img/faqs/chevron-down.svg"
                    alt="Expand"
                    style={{
                      transform:
                        activeIndex === `${catIndex}-${qIndex}`
                          ? "rotate(180deg)"
                          : "rotate(0)",
                      transition: "transform 0.3s",
                    }}
                  />
                </div>
                {activeIndex === `${catIndex}-${qIndex}` && (
                  <div className="faq-question-details">{question.details}</div>
                )}
              </div>
            ))
          )}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
