import React, { useState } from 'react';
import ProgressBar from '../../components/checkout/recipient/ProgressBar2';
import Navbar from '../../components/marketplace/nav/nav';
import RecipientPage from '../../components/checkout/recipient/RecipientPage';

const Recipient = () => (
  <>
    <Navbar />
    <ProgressBar />
    <RecipientPage />
  </>
);

export default Recipient;
