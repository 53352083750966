import React, { useState, useEffect, useRef } from 'react';
import styles from './SecondaryNav.module.css';
import CountryPicker from '../../countryPicker/CountryPicker';
import { Link } from 'react-router-dom';

const SecondaryNav = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMobileMenuOpen(false);
      }
    };

    if (isMobileMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  const toggleMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className={styles.lipaworld_container}>
      {/* Desktop Navigation */}
      <nav className={styles.lipaworld_secondaryNav}>
        <div className={styles.lipaworld_desktopNav}>
          <div className={styles.lipaworld_navLinks}>
            <Link to="/" className={styles.lipaworld_navLink}>
              Home
            </Link>
            <Link to="/contact/?selected-tab=faqs" className={styles.lipaworld_navLink}>
              FAQs
            </Link>
            <Link to="/blog" className={styles.lipaworld_navLink}>
              Blog
            </Link>
          </div>

          <div className={styles.lipaworld_localeSelector}>
            <button className={styles.lipaworld_localeButton}>
              {/* <img src="/img/shop/us1.png" alt="US" className={styles.lipaworld_flagIcon} />
              <span>US</span>
              <img src="/img/shop/arrow-right.png" /> */}
              <CountryPicker />
            </button>
          </div>
        </div>

        {/* Mobile Navigation */}
        <div className={styles.lipaworld_mobileNav}>
          <button
            type="button"
            className={styles.lipaworld_hamburgerButton}
            onClick={toggleMenu}
            aria-label="Toggle menu"
          >
            <span className={styles.lipaworld_hamburgerIcon}></span>
          </button>

          <div className={styles.lipaworld_mobileLocaleSelector}>
            <button className={styles.lipaworld_localeButton}>
              {/* <img src="/img/shop/us1.png" alt="US" className={styles.lipaworld_flagIcon} />
              <span>US</span>
              <img src="/img/shop/arrow-right.png" /> */}
              <CountryPicker />
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && <div className={styles.lipaworld_overlay} onClick={() => setIsMobileMenuOpen(false)} />}
        <div ref={menuRef} className={`${styles.lipaworld_mobileMenu} ${isMobileMenuOpen ? styles.open : ''}`}>
          <div className={styles.lipaworld_mobileMenuHeader}>
            <button
              className={styles.lipaworld_closeButton}
              onClick={() => setIsMobileMenuOpen(false)}
              aria-label="Close menu"
            >
              ✕
            </button>
          </div>
          <div className={styles.lipaworld_mobileMenuContent}>
            <Link to="/marketplace" className={styles.lipaworld_mobileMenuItem}>
              Shop
            </Link>

            <Link to="/contact/?selected-tab=faqs" className={styles.lipaworld_mobileMenuItem}>
              FAQs
            </Link>
            <Link to="/blog" className={styles.lipaworld_mobileMenuItem}>
              Blog
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default SecondaryNav;
