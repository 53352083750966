import React, { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import styles from "../styles/phonenumber.module.css";

const PhoneNumber = () => {
  const { authState, updateAuthState } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!authState.email || !authState.isVerified) {
      navigate("/signup");
    }
  }, [authState, navigate]);

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const authUrl = process.env.REACT_APP_API_USERS_URL;
      const response = await fetch(`${authUrl}/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: authState.email,
          password: authState.tempPassword,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Login failed");
      }

      localStorage.setItem("token", data.token);

      updateAuthState({
        isAuthenticated: true,
        id: data.user.id,
        email: data.user.email,
        firstName: data.user.firstName,
        lastName: data.user.lastName,
        phoneNumber: data.user.phoneNumber,
        userType: data.user.userType,
        indexNumber: data.user.indexNumber,
        createdAt: data.user.createdAt,
        updatedAt: data.user.updatedAt,
      });

      navigate("/marketplace");
    } catch (error) {
      toast.error(error.message || "Failed to login");
      navigate("/signin");
    } finally {
      setIsLoading(false);
    }
  };

  const addNumber = () => {
    navigate("/addphonenumber");
  };

  const shop = () => {
    handleLogin();
  };

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <img
          src="/img/auth/logo-black2.png"
          alt="Logo"
          className={styles.logo}
        />
      </div>
      <div className={styles.loggedInInfo}>
        <span className={styles.loggedInText}>Logged in to</span>
        <span className={styles.email}>{authState.email}</span>
      </div>
      <div className={styles.content}>
        <h1 className={styles.title}>
          Add Your Phone Number or Continue Shopping
        </h1>
        <p className={styles.description}>
          Would you like to add your phone number for a more secure and
          personalized experience?
        </p>
        <div className={styles.buttonGroup}>
          <button className={styles.addButton} onClick={addNumber}>
            Yes, add my number
          </button>
          <button
            className={styles.continueButton}
            onClick={shop}
            disabled={isLoading}
          >
            {isLoading ? "Please wait..." : "No, continue shopping"}
          </button>
        </div>
        <p className={styles.note}>
          You can always add your phone number later in your account settings.
        </p>
      </div>
    </div>
  );
};

export default PhoneNumber;
