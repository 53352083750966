import React from 'react';
import styles from './PinsDisplay.module.css';

// interface ActionButtonProps {
//   action: string;
//   onClick: () => void;
//   showButton?: boolean;
// }

const ActionButton = ({ action, onClick, showButton = true }) => {
  if (!showButton) {
    return null;
  }

  return <input type="button" className={styles.actionButtonLink} value={`${action}`} onClick={onClick} />;
};

export default ActionButton;
