import React from "react";

const IncludeProcessingFee = ({
  includeProcessingFee,
  setIncludeProcessingFee,
  institution,
}) => {
  return (
    <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
      <input
        type="checkbox"
        checked={includeProcessingFee}
        onChange={(event) => setIncludeProcessingFee(event.target.checked)}
        color="success"
      />{" "}
      <label>Help cover costs and fees for {institution}.</label>
    </div>
  );
};

export default IncludeProcessingFee;
