import React, { useEffect, useRef, useState } from "react";

const categories = [
  {
    icon: "/img/partnerpage/bill.png",
    title: "Utilities",
    description:
      "Allow customers to pay for utility services like electricity and water.",
  },
  {
    icon: "/img/partnerpage/math.png",
    title: "Education",
    description:
      "Provide access to learning materials, school fees, and educational services.",
  },
  {
    icon: "/img/partnerpage/shopping-cart.png",
    title: "Groceries",
    description:
      "Help families stay connected by offering fresh food and groceries from back home.",
  },
  {
    icon: "/img/partnerpage/gift.png",
    title: "Donations",
    description:
      "Enable customers to make contributions that support causes back home.",
  },
  {
    icon: "/img/partnerpage/gas-station.png",
    title: "Fuel",
    description:
      "Make it easier for customers to pay for fuel for their loved ones.",
  },
  {
    icon: "/img/partnerpage/hospital.png",
    title: "Health care",
    description:
      "Help families access essential medical care and health products.",
  },
  {
    icon: "/img/partnerpage/car.png",
    title: "Travel and Transport",
    description:
      "Offer transport services or tickets for local and international travel.",
  },
  {
    icon: "/img/partnerpage/call-calling.png",
    title: "Communication",
    description:
      "Support services that keep communication flowing, such as phone plans and internet services.",
  },
  {
    icon: "/img/partnerpage/play-cricle.png",
    title: "Entertainment",
    description:
      "Feature entertainment services like streaming, movies, and games.",
  },
  {
    icon: "/img/partnerpage/receipt-item.png",
    title: "Insurance",
    description:
      "Help families with access to insurance products, providing peace of mind.",
  },
];

const Explore = () => {
  const scrollRef = useRef(null);
  const scrollPositionRef = useRef(0);
  const lastTimeRef = useRef(null);
  const pausedCardRef = useRef(null);

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    let animationFrameId;

    const animate = (timestamp) => {
      if (!lastTimeRef.current) {
        lastTimeRef.current = timestamp;
      }

      const deltaTime = timestamp - lastTimeRef.current;
      lastTimeRef.current = timestamp;

      if (scrollContainer && !pausedCardRef.current) {
        // Update scroll position
        scrollPositionRef.current += deltaTime * 0.1;

        // Reset position when reaching the end of the first set
        if (scrollPositionRef.current >= scrollContainer.scrollWidth / 2) {
          scrollPositionRef.current = 0;
        }

        scrollContainer.scrollLeft = scrollPositionRef.current;
      }

      animationFrameId = requestAnimationFrame(animate);
    };

    animationFrameId = requestAnimationFrame(animate);

    // Ensure animation continues even when tab is not focused
    const visibilityHandler = () => {
      lastTimeRef.current = null;
    };
    document.addEventListener("visibilitychange", visibilityHandler);

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
      document.removeEventListener("visibilitychange", visibilityHandler);
    };
  }, []);

  return (
    <section className="explore-section explorebody">
      <div className="explore-header">
        <h1>Explore Our Marketplace Categories</h1>
        <p>
          Send your loved ones a range of essential products and services such
          as prepaid electricity tokens, healthcare vouchers, grocery vouchers,
          airtime and data in Africa and so much more.
        </p>
      </div>

      <div className="explorecard-container">
        <div className="explorecard-scroll" ref={scrollRef}>
          {[...categories, ...categories].map((category, index) => (
            <div
              className="explorecard"
              key={index}
              onMouseEnter={() => (pausedCardRef.current = true)}
              onMouseLeave={() => (pausedCardRef.current = false)}
            >
              <div className="exploreicon-wrapper">
                <img src={category.icon} alt={category.title} />
              </div>
              <h3>{category.title}</h3>
              <p>{category.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Explore;
