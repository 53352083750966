import React from "react";
import { useNavigate } from "react-router-dom";
import { useCart } from "../../context/CartContext";
import styles from "./CartIcon.module.css";

const CartIcon = () => {
  const cart = useCart();
  const navigate = useNavigate();
  const routeToCart = () => {
    navigate("/checkout");
  };
  return (
    <button className={styles.lipaworld_navButton} onClick={routeToCart}>
      {cart && cart?.cartItems.length > 0 && (
        <div className={styles.lipaworld_quantity}>{cart.cartItems.length}</div>
      )}
      <img
        className={styles.lipaworld_navIcon}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        src="/img/shop/shopping-cart.svg"
        alt="Cart"
      />
      <span>Cart</span>
    </button>
  );
};

export default CartIcon;
