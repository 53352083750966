import React, { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTransaction, useDispatchCart } from '../../../context';
import { useAuth } from '../../../context/AuthContext';
import VoucherProcessStatus from '../../../components/voucherProcessStatus/VoucherProcessStatus';
import styles from './Completion.module.css';

const Completion = () => {
  const { authState } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const user = authState;
  const transaction = useTransaction();
  const dispatchCart = useDispatchCart();
  const hasRequested = React.useRef(false);

  const payment = typeof window !== 'undefined' ? JSON.parse(sessionStorage.getItem('payment')) : null;

  useEffect(() => {
    const handlePaymentCompletion = () => {
      if (payment?.purchase_units[0]?.payments?.captures[0]?.status === 'COMPLETED' && !hasRequested.current) {
        hasRequested.current = true;
        voucherHandler(payment.id);
      }
    };

    handlePaymentCompletion();
  }, []);

  const voucherHandler = async (completionId) => {
    try {
      if (!transaction?.transactionId || transaction?.transactionId.length < 2) {
        throw new Error('Transaction not found. Try again later');
      }

      const requestConfig = {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_API_KEY,
        },
      };
      const requestBody = {
        transaction,
        cart: JSON.parse(sessionStorage.getItem('cart')),
        appliedDiscountCodes: JSON.parse(sessionStorage.getItem('appliedDiscountCodes')) ?? [],
        sender: user,
        paymentCompletionId: completionId,
      };
      const url = `${process.env.REACT_APP_API_VOUCHERS_URL}/p-vouchers`;
      await axios.post(url, requestBody, requestConfig);

      emptyCart();
      sessionStorage.removeItem('cart');
      sessionStorage.removeItem('recipients');
      sessionStorage.removeItem('payment');
      sessionStorage.removeItem('appliedDiscountCodes');

      navigate(`/vouchers?transactionId=${transaction?.transactionId}`);
    } catch (error) {
      emptyCart();
      sessionStorage.removeItem('cart');
      sessionStorage.removeItem('recipients');
      sessionStorage.removeItem('payment');
      sessionStorage.removeItem('appliedDiscountCodes');
      navigate(`/vouchers?transactionId=${transaction?.transactionId}`);
    }
  };

  const removeFromCartHandler = (cartItemId) => {
    try {
      dispatchCart({ type: 'REMOVE_CART_ITEM', payload: cartItemId });
    } catch (error) {
      toast.error('Error removing item from cart. Please try again later.');
    }
  };

  const emptyCart = () => {
    const cart = JSON.parse(sessionStorage.getItem('cart'));
    if (cart && cart.cartItems.length > 0) {
      cart.cartItems.forEach((item) => {
        removeFromCartHandler(item.cartItemId);
      });
    }
  };

  return (
    <>
      {/* <Head>
        <noscript>
          <img height="1" width="1" src="https://www.facebook.com/tr?id=1181109959982531&ev=PageView&noscript=1" />
        </noscript>
      </Head> */}

      <script>
        {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window,document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '1181109959982531'); 
          fbq('track', 'PageView');
          fbq('track', 'Purchase', {value: 0.00, currency: 'USD'});
          `}
      </script>

      <main className={styles.main}>
        <VoucherProcessStatus payment={payment} />
      </main>
    </>
  );
};

export default Completion;
