import React from 'react';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

import ProgressBar from '../../components/checkout/payment/ProgressBar3';
import Navbar from '../../components/marketplace/nav/nav';
import PaymentPage from '../../components/checkout/payment/PaymentPage';

const Payment = () => {
  const initialOptions = {
    'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
    currency: 'USD',
    intent: 'capture',
    enableFunding: 'venmo,card,credit',
  };

  return (
    <PayPalScriptProvider options={initialOptions}>
      <Navbar />
      <ProgressBar />
      <PaymentPage />
    </PayPalScriptProvider>
  );
};

export default Payment;
