import React from 'react';
import styles from './nav.module.css';

const SearchDropdown = ({ isVisible }) => {
  if (!isVisible) return null;

  return (
    <div className={styles.searchDropdown}>
      <div className={styles.searchContent}>
        <div className={styles.popularSection}>
          <h2 className={styles.sectionTitle}>Popular</h2>
          <div className={styles.suggestions}>
            <div className={styles.suggestionItem}>MTN Voucher</div>
            <div className={styles.suggestionItem}>Airtel Voucher</div>
            <div className={styles.suggestionItem}>Dstv</div>
            <div className={styles.suggestionItem}>Gotv</div>
            <div className={styles.suggestionItem}>Shoprite</div>
            <div className={styles.suggestionItem}>Cowry</div>
            <div className={styles.suggestionItem}>Item Suggestion</div>
            <div className={styles.suggestionItem}>Item Suggestion</div>
          </div>
        </div>
        
        <div className={styles.recentSection}>
          <h2 className={styles.sectionTitle}>Recent searches</h2>
          <ul className={styles.recentList}>
            {Array(8).fill('Item').map((item, index) => (
              <li key={index} className={styles.recentItem}>{item}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SearchDropdown;