import React, { useState } from 'react';
import Navbar from '../../components/marketplace/nav/nav';
import PaymentSuccessPage from '../../components/checkout/status/PaymentSuccessPage';

const Success = () => (
  <>
    <Navbar />
    <PaymentSuccessPage />
  </>
);

export default Success;
