import React from 'react';
import { Link } from 'react-router-dom';

const Fostering = () => {
  return (
    <div className="fostering-container">
      <div className="image-section" data-aos="fade-down">
        <img
          src="/img/blog/Banner.png"
          alt="Lipaworld Christmas banner"
          className="community-image"
        />
      </div>
      <div className="content-section" data-aos="fade-right">
        <div className="category-and-title">
          <div className="metadata">
            <span className="category">Article</span>
            <span className="separator">•</span>
            <span className="read-time">10min read</span>
            <span className="separator">•</span>
            <span className="date">December 11, 2024</span>
          </div>
          <h2 className="title">Sharing Love Across Borders</h2>
        </div>
        <p className="description">
        Here’s a guide to help you navigate the process of sending love home this holiday season.
        </p>
        <div className="author-section">
          {/* <img
            src="/img/blog/rev1.png"
            alt="Lipaworld"
            className="avatar"
          /> */}
          <span className="author-name">Lipaworld</span>
        </div>
        <Link to='/blog/sharing-love-across-borders' className="read-more-button"> <button className="read-more-button">Read more</button></Link>
      </div>
    </div>
  );
};

export default Fostering;
