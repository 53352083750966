import React from 'react';
import { useQuery } from '@tanstack/react-query';
// import { ICartItem } from '@/types/ICart';
// import { IUser } from '@/types/IUser';
import { calculateRedemptionValue } from '../../utils/calculateRedemptionValue';
import styles from './PinsDisplay.module.css';
import ShareActions, { DealActions } from '../../components/shareActions/ShareActions';
import {
  sendPinSmsMessage,
  sendTopUpSmsMessage,
  sendPinWhatsAppMessage,
  sendTopUpWhatsAppMessage,
  sendPinEmailMessage,
  sendTopUpEmailMessage,
} from '../../utils/sendPins';

// interface IPinDisplayProps {
//   transactionId?: string;
//   item: ICartItem;
//   user: IUser;
// }

// interface IPinVoucher {
//   vouchers: { pin: string; status?: { message: string } }[];
//   retrievedPinData: any;
//   isLoading: boolean;
//   isError: boolean;
// }

const fetchPin = async (transactionId) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_VOUCHERS_URL}/dtone-transaction-query?transactionId=${transactionId}`,
  );
  if (!response.ok) {
    throw new Error('Failed to fetch pin');
  }
  const data = await response.json();
  return data;
};

const VoucherList = ({ vouchers, retrievedPinData, isLoading, isError }) => {
  if (isError) return <div>Failed to load</div>;

  const status = isLoading ? 'LOADING...' : retrievedPinData[0]?.status?.message;
  const pin = isLoading ? 'LOADING...' : retrievedPinData[0]?.pin?.code;

  if (status === 'DECLINED') return <p style={{ fontSize: '1rem' }}>Status: {status}</p>;

  return (
    <>
      {vouchers &&
        vouchers.length > 0 &&
        vouchers.map((voucher, index) => (
          <>
            {/* {voucher?.status?.message && (
            <p key={index} style={{ fontSize: '28px' }}>
              <strong>STATUS: {voucher?.status?.message}</strong>
            </p>
          )} */}
            {/* {voucher[0]?.pin?.status?.message ? (
            <div key={index}>
              <strong>STATUS: {voucher[0]?.pin?.status?.message}</strong>
            </div>
          ) : null} */}
            {isLoading ? (
              <p key={index} style={{ fontSize: '28px' }}>
                {status}
              </p>
            ) : (
              <p key={index} style={{ fontSize: '28px' }}>
                {/* <strong>PIN: {voucher?.pin ?? '---'}</strong> */}
                <strong>PIN: {voucher?.pin ?? pin ?? 'Not available'}</strong>
              </p>
            )}
          </>
        ))}
    </>
  );
};

const PinsDisplay = ({ item, user, transactionId = '' }) => {
  const {
    data: retrievedPinData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['fetch-pin_dtone', transactionId],
    queryFn: () => fetchPin(transactionId),
    enabled: item?.vouchers && !item?.vouchers[0]?.pin,
    retry: 5,
    retryDelay: 5000,
  });

  return (
    <div key={item.deal.dealId} className={styles.voucherContainer}>
      {item.quantity} x {item.deal.redemptionCurrency} {calculateRedemptionValue(item).toFixed(2)}{' '}
      {item.deal.voucherName} voucher at {item.deal.merchantName}
      <br />
      <br />
      {item?.vouchers && item?.vouchers?.length > 0 ? (
        <VoucherList
          vouchers={item?.vouchers}
          retrievedPinData={retrievedPinData ?? []}
          isLoading={isLoading}
          isError={isError}
        />
      ) : (
        <p>No vouchers to display.</p>
      )}
      <br /> <br />
      {item.productRecipient && item.productRecipient.name && user && (
        <ShareActions
          recipientName={item.productRecipient.name}
          item={item}
          user={user}
          sendWhatsAppMessage={sendPinWhatsAppMessage}
          sendEmailMessage={sendPinEmailMessage}
          sendSmsMessage={sendPinSmsMessage}
        />
      )}
    </div>
  );
};

// country: ""
// ​
// createdAt: "2024-11-21T08:54:39.624Z"
// ​
// email: "victorbagu@gmail.com"
// ​
// firstName: "Victor"
// ​
// id: "USR-f458d428-50b1-70a7-442d-681da3914f3c"
// ​
// indexNumber: 1732179279624
// ​
// isAuthenticated: true
// ​
// isVerified: false
// ​
// lastName: "Bagu"
// ​
// phoneNumber: ""
// ​
// tempPassword: ""
// ​
// updatedAt: "2024-11-21T08:54:39.624Z"
// ​
// userType: "SOCIAL"

const TopUpsDisplay = ({ item, user }) => {
  const recipientName = item.productRecipient?.name || item.productRecipient?.firstName;

  return (
    <div key={item.deal.dealId} className={styles.voucherContainer}>
      {item.quantity} x {item.deal.redemptionCurrency} {calculateRedemptionValue(item).toFixed(2)}{' '}
      {item.deal.voucherName} was topped-up
      {item.productRecipient && item.productRecipient.mobileNumber && (
        <>
          {' '}
          to <strong>{item.productRecipient.mobileNumber}</strong>
        </>
      )}
      .
      <br /> <br />
      {/* {item.productRecipient && recipientName && user && (
        <ShareActions
          recipientName={recipientName}
          item={item}
          user={user}
          sendWhatsAppMessage={sendTopUpWhatsAppMessage}
          sendEmailMessage={sendTopUpEmailMessage}
          sendSmsMessage={sendTopUpSmsMessage}
        />
      )} */}
    </div>
  );
};

const DonationsDisplay = ({ item, user }) => {
  return (
    <div key={item.deal.dealId} style={{ marginBottom: '50px' }}>
      Your donation of {item.quantity} x {item.deal.redemptionCurrency} {calculateRedemptionValue(item).toFixed(2)}{' '}
      {item.deal.voucherName} is being sent over to {item.deal.merchantName}.
      <br />
      <br />
      <DealActions
        redemptionInstructions={item.deal.redemptionInstructions}
        voucherDescription={item.deal.voucherDescription}
        terms={item.deal.terms}
        productName={item.deal.voucherName}
      />
    </div>
  );
};

export { PinsDisplay, TopUpsDisplay, DonationsDisplay };
