import React from 'react';

import Navbar from '../../components/marketplace/nav/nav';
import Completion from '../../components/checkout/payment/Completion';

const PaymentCompletion = () => (
  <>
    <Navbar />
    <Completion />
  </>
);

export default PaymentCompletion;
