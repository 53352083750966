import React, { useState, useEffect } from "react";
import { useAuth } from "../context";
import { Eye, EyeOff } from "lucide-react";
import styles from "../styles/signup.module.css";
import { useNavigate, Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import countries from "../utils/countries";

const SignupForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { signUp } = useAuth();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const parseJwt = (token) => {
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join("")
      );
      return JSON.parse(jsonPayload);
    } catch (e) {
      console.error("Invalid JWT token:", e);
      return null;
    }
  };

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    const hashParams = new URLSearchParams(hash);

    const access_token = hashParams.get("access_token");
    const id_token = hashParams.get("id_token");
    const error = hashParams.get("error");

    if (access_token && id_token) {
      try {
        const decodedToken = parseJwt(id_token);

        signUp(
          {
            email: decodedToken.email,
            firstName: decodedToken.given_name || "",
            lastName: decodedToken.family_name || "",
          },
          true
        );

        localStorage.setItem("access_token", access_token);
        localStorage.setItem("id_token", id_token);

        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );

        navigate("/marketplace");
      } catch (error) {
        console.error("Google OAuth Processing Error:", error);
        toast.error("Authentication failed");
      }
    } else if (error) {
      toast.error("Google authentication failed");

      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [navigate, signUp]);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const authUrl = process.env.REACT_APP_API_USERS_URL;
      const response = await fetch(`${authUrl}/sign-up`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (!response.ok) {
        if (
          data.error?.includes("already exists") ||
          data.message?.includes("user already exists")
        ) {
          toast.error(
            "An account with this email already exists. Please sign in."
          );

          setTimeout(() => {
            navigate("/signin");
          }, 2000);

          return;
        }

        throw new Error(data.error || data.message || "Signup failed");
      }

      if (response.ok) {
        signUp({
          email: formData.email,
          firstName: formData.firstName,
          lastName: formData.lastName,
          tempPassword: formData.password,
        });
      }

      toast.success("Signup successful! Please verify your email.");
      navigate("/emailverify");
    } catch (error) {
      const errorMessage =
        error.name === "TypeError"
          ? "Unable to connect to the server. Please check your internet connection."
          : error.message;

      toast.error(errorMessage);
      console.error("Signup error:", error);
    } finally {
      setLoading(false);
    }
  };

  // const handleGoogleAuth = () => {
  //   const baseUrl = process.env.REACT_APP_BASE_URL;
  //   const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  //   const authUrl = process.env.REACT_APP_AUTH_URL;

  //   const url =
  //     `${authUrl}/oauth2/authorize?` +
  //     `identity_provider=Google&` +
  //     `redirect_uri=${encodeURIComponent(`${baseUrl}/marketplace`)}` +
  //     `&response_type=token` +
  //     `&client_id=${googleClientId}`;

  //   window.location.href = url;
  // };

  return (
    <div className={styles.container}>
      <Toaster
        position="bottom-left"
        toastOptions={{
          duration: 5000,
          style: {
            background: "#333",
            color: "#fff",
          },
        }}
      />

      <div className={styles.formWrapper}>
        <div className={styles.logoContainer}>
          <Link to="/">
            <img
              src="/img/auth/logo-black2.png"
              alt="Lipaworld logo"
              className={styles.logo}
            />
          </Link>
        </div>

        <div className={styles.welcomeText}>
          <h1>Welcome to Lipaworld!</h1>
          <p>
            Create an account to start sending value home easily and securely.
          </p>
        </div>

        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.nameFields}>
            <div className={styles.inputGroup}>
              <label>First Name</label>
              <input
                type="text"
                name="firstName"
                placeholder="John"
                value={formData.firstName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className={styles.inputGroup}>
              <label>Last Name</label>
              <input
                type="text"
                name="lastName"
                placeholder="Doe"
                value={formData.lastName}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          <div className={styles.inputGroup}>
            <label>Email Address</label>
            <input
              type="email"
              name="email"
              placeholder="Enter your email address"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className={styles.inputGroup}>
            <label>Password</label>
            <div className={styles.passwordInput}>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Enter your password"
                value={formData.password}
                onChange={handleInputChange}
                required
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className={styles.passwordToggle}
              >
                {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </button>
            </div>
            <p className={styles.passwordHint}>
              Password must be at least 8 characters long and contain uppercase,
              lowercase, numbers and special characters
            </p>
          </div>

          <div className={styles.termsGroup}>
            <input type="checkbox" id="terms" required />
            <label htmlFor="terms">
              By signing up, you agree to our{" "}
              <Link to="/terms-of-use" target="_blank">
                Terms & Conditions
              </Link>{" "}
              and{" "}
              <Link to="/privacy-policy" target="_blank">
                Privacy Policy
              </Link>
            </label>
          </div>

          <button
            type="submit"
            className={styles.signupButton}
            disabled={loading}
          >
            {loading ? "Signing up..." : "Sign Up"}
          </button>

          {/* <button
            type="button"
            className={styles.googleButton}
            onClick={handleGoogleAuth}
            disabled={loading}
          >
            <img
              src="/img/auth/google.png"
              alt="Google logo"
              className={styles.googleIcon}
            />
            Continue with Google
          </button> */}

          <p className={styles.signin}>
            Already have an account? <Link to="/signin">Sign in here</Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default SignupForm;
