import React from 'react';
// import { ICartItem } from '@/types/ICart';
// import { IUser } from '@/types/IUser';
import ActionButton from '../completion/ActionButton';
import Dialog from '../dialog/Dialog';
import styles from './ShareActions.module.css';
import { useIsMobile } from '../../hooks/useIsMobile';

// interface IShareActionsProps {
//   recipientName: string;
//   item: ICartItem;
//   user: IUser;
//   sendWhatsAppMessage: (item: ICartItem, user: IUser) => void;
//   sendEmailMessage: (item: ICartItem, user: IUser) => void;
//   sendSmsMessage: (item: ICartItem, user: IUser) => void;
//   hideSendLinks?: boolean;
// }
// interface IDealActionsProps {
//   redemptionInstructions: string;
//   terms: string;
//   voucherDescription?: string;
//   productName?: string;
// }

export const DealActions = ({ redemptionInstructions, terms, voucherDescription, productName }) => {
  const [isRedemptionDialogOpen, setIsRedemptionDialogOpen] = React.useState(false);
  const [isTermsDialogOpen, setIsTermsDialogOpen] = React.useState(false);

  const titleRedemptionInstructions = productName
    ? `Redemption Instructions - ${productName}`
    : 'Redemption Instructions';
  const titleTerms = productName ? `Terms & Conditions - ${productName}` : 'Terms & Conditions';

  return (
    <>
      {voucherDescription && (
        <>
          <small>{voucherDescription}</small>
          <br />
        </>
      )}

      {redemptionInstructions && (
        <>
          Read{'   '}
          <ActionButton action="redemption instructions" onClick={() => setIsRedemptionDialogOpen(true)} />
          <Dialog
            title={titleRedemptionInstructions}
            isOpen={isRedemptionDialogOpen}
            onClose={() => setIsRedemptionDialogOpen(false)}
          >
            {redemptionInstructions}
          </Dialog>
          <br />
        </>
      )}

      {terms && (
        <>
          Read{'   '}
          <ActionButton action="terms & conditions" onClick={() => setIsTermsDialogOpen(true)} />
          <Dialog title={titleTerms} isOpen={isTermsDialogOpen} onClose={() => setIsTermsDialogOpen(false)}>
            {terms}
          </Dialog>
          <br />
        </>
      )}
    </>
  );
};

const ShareActions = ({
  recipientName,
  item,
  user,
  sendWhatsAppMessage,
  sendEmailMessage,
  sendSmsMessage,
  hideSendLinks = false,
}) => {
  const isMobile = useIsMobile();

  return (
    <div className={styles.actionButtonContainer}>
      {!hideSendLinks ? (
        <div>
          Share with {recipientName} on{'   '}
          <ActionButton action="WhatsApp" onClick={() => sendWhatsAppMessage(item, user)} />
          {'   '}
          <ActionButton action="Email" onClick={() => sendEmailMessage(item, user)} />
          {'   '}
          <ActionButton action="SMS" onClick={() => sendSmsMessage(item, user)} showButton={isMobile} />
        </div>
      ) : (
        <div></div>
      )}
      <div>
        <DealActions
          productName={item.deal.voucherName}
          redemptionInstructions={item.deal.redemptionInstructions}
          terms={item.deal.terms}
          voucherDescription={item.deal.categories.includes('Donations') ? item.deal.voucherDescription : undefined}
        />
      </div>
    </div>
  );
};

export default ShareActions;
