import React, { useState, useRef, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useSelectedCountry } from "../../../context/SelectedCountryContext";
import styles from "./FeaturedCategories.module.css";
import { useNavigate, useLocation } from "react-router-dom";

const categories = [
  { id: 1, name: "Utilities", image: "/img/partnerpage/bill.png" },
  { id: 2, name: "Insurance", image: "/img/partnerpage/bill.png" },
  { id: 3, name: "Flash", image: "/img/partnerpage/bill.png" },
  { id: 4, name: "Education", image: "/img/partnerpage/math.png" },
  { id: 5, name: "Groceries", image: "/img/partnerpage/shopping-cart.png" },
  { id: 6, name: "Gaming", image: "/img/partnerpage/shopping-cart.png" },
  { id: 7, name: "Entertainment", image: "/img/partnerpage/shopping-cart.png" },
  { id: 8, name: "Dining", image: "/img/partnerpage/shopping-cart.png" },
  { id: 9, name: "Donations", image: "/img/partnerpage/gift.png" },
  { id: 10, name: "Fuel", image: "/img/partnerpage/gas-station.png" },
  { id: 11, name: "Healthcare", image: "/img/partnerpage/hospital.png" },
  { id: 12, name: "Transport", image: "/img/partnerpage/car.png" },
  { id: 13, name: "Travel and Transport", image: "/img/partnerpage/car.png" },
  { id: 15, name: "Travel", image: "/img/partnerpage/car.png" },
  { id: 14, name: "Communication", image: "/img/partnerpage/call-calling.png" },
  { id: 16, name: "Data", image: "/img/partnerpage/call-calling.png" },
  { id: 17, name: "Airtime", image: "/img/partnerpage/call-calling.png" },
  { id: 18, name: "Shopping", image: "/img/partnerpage/shopping-cart.png" },
  { id: 19, name: "Bundle", image: "/img/partnerpage/shopping-cart.png" },
];

const FeaturedCategories = () => {
  const { selectedCountry } = useSelectedCountry();
  const sliderRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const { isLoading, isError, data } = useQuery({
    queryKey: ["categories", selectedCountry.countryCode],
    queryFn: async () => {
      if (selectedCountry.countryCode) {
        const { data } = await axios.get(
          `${process.env.REACT_APP_DEALS_API_URL}/deals/categories?country=${selectedCountry.countryCode}`
        );
        return data;
      }
    },
  });

  const checkScroll = () => {
    if (sliderRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = sliderRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth - 10);
    }
  };

  const scroll = (direction) => {
    if (sliderRef.current) {
      const scrollAmount = direction === "left" ? -280 : 280;
      sliderRef.current.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (data) {
      const filteredCategories = categories.filter((category) =>
        data.includes(category.name)
      );
      setFilteredCategories(filteredCategories);
    }
  }, [data]);

  const handleCategoryClick = (category) => {
    if (location.pathname === "/marketplace") {
      // If already on shop page, scroll to category
      const element = document.getElementById(`category-${category.name}`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      // If not on shop page, navigate and then scroll
      navigate(
        `/marketplace?category=${category.name}&countryCode=${selectedCountry.countryCode}`,
        {
          state: { scrollToCategory: category.name },
        }
      );
    }
  };

  return (
    <div className={styles.lipaworld_featuredContainer}>
      <div className={styles.lipaworld_header}>
        <h2 className={styles.lipaworld_title}>Featured categories</h2>
        <div className={styles.lipaworld_navigation}>
          <button
            className={`${styles.lipaworld_navButton} ${
              !canScrollLeft ? styles.lipaworld_navButtonDisabled : ""
            }`}
            onClick={() => scroll("left")}
            disabled={!canScrollLeft}
          >
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
            </svg>
          </button>
          <button
            className={`${styles.lipaworld_navButton} ${
              !canScrollRight ? styles.lipaworld_navButtonDisabled : ""
            }`}
            onClick={() => scroll("right")}
            disabled={!canScrollRight}
          >
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path d="M8.59 16.59L10 18l6-6-6-6-1.41 1.41L13.17 12z" />
            </svg>
          </button>
        </div>
      </div>

      <div
        className={styles.lipaworld_categoriesSlider}
        ref={sliderRef}
        onScroll={checkScroll}
      >
        {filteredCategories.map((category) => (
          <div
            key={category.id}
            onClick={() => handleCategoryClick(category)}
            className={styles.lipaworld_categoryCard}
            style={{ cursor: "pointer" }}
          >
            <div className={styles.lipaworld_iconWrapper}>
              <img className={styles.lipaworld_icon} src={category.image} />
            </div>
            <span className={styles.lipaworld_categoryName}>
              {category.name}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturedCategories;
