import React, { useState, useRef, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import VoucherDetails from '../voucher-details/voucherDetails';
import DisplayedCardAmounts from '../../displayedCardAmounts/DisplayedCardAmounts';
import { useIsMobile } from '../../../hooks/useIsMobile';
import styles from './ProductSection.module.css';

const ProductCard = ({ title, onClick, voucherImageUrl, product, redemptionInputValue }) => (
  <div className={styles.lipaworld_productCard} onClick={onClick}>
    <div className={styles.lipaworld_productImage}>
      <img src={voucherImageUrl} />
    </div>
    <div className={styles.lipaworld_notchContainer}>
      <div className={styles.lipaworld_leftNotch}></div>
      <div className={styles.lipaworld_dottedLine}></div>
      <div className={styles.lipaworld_rightNotch}></div>
    </div>
    <div className={styles.lipaworld_productInfo}>
      <h3 className={styles.lipaworld_productTitle}>{title}</h3>
      <div className={styles.lipaworld_productPrice}>
        {product?.usdAmount != null && product?.usdAmount === 0 ? (
          <></>
        ) : (
          <DisplayedCardAmounts redemptionInputValue={redemptionInputValue} product={product} currencyRate={1} />
        )}
      </div>
    </div>
    {product.status === 'Coming Soon' ? (
      <button className={styles.lipaworld_disabledButton} disabled>
        Coming soon
      </button>
    ) : product.stockQuantity === 0 && !product.categories.includes('Education') ? (
      <button className={styles.lipaworld_disabledButton} disabled>
        Out of stock
      </button>
    ) : (
      <button className={styles.lipaworld_addToCartButton}>
        Add to Cart
        <svg width="20" height="20" viewBox="0 0 24 24" className={styles.lipaworld_cartIcon}>
          <path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 3h1l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h11c.55 0 1-.45 1-1s-.45-1-1-1H7l1.1-2h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.67-1.43c-.16-.35-.52-.57-.9-.57H1c-.55 0-1 .45-1 1s.45 1 1 1zm16 15c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z" />
        </svg>
      </button>
    )}
  </div>
);

const ProductsByCategory = ({ category, countryCode }) => {
  const isMobile = useIsMobile();
  const scrollContainerRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [filteredDeals, setFilteredDeals] = useState([]);
  const [headingPlaceholder, setHeadingPlaceholder] = useState('');

  const [modalState, setModalState] = useState({
    isOpen: false,
    selectedProduct: null,
  });

  const [showScrollButtons, setShowScrollButtons] = useState(false);
  const [redemptionValues, setRedemptionValues] = useState({});

  const formatCustomAmount = (status) => {
    if (status === false || status === true) {
      return status;
    } else if (status === 'false') {
      return false;
    } else if (status === 'true') {
      return true;
    } else {
      return false;
    }
  };

  const {
    isLoading,
    isError,
    data: deals,
    error,
  } = useQuery({
    queryKey: ['category_deals', countryCode, category],
    queryFn: async () => {
      if (countryCode && category) {
        const { data } = await axios.get(
          `${process.env.REACT_APP_DEALS_API_URL}/deals/category?category=${category}&country=${countryCode}`,
        );

        const mappedProducts =
          data &&
          data.map((product) => ({
            merchantId: product.merchant_id,
            categories: [product.category],
            merchantName: product.merchant_name,
            countryCode: product.country,
            dealId: product.product_id,
            partnerProductId: product.partner_product_id,
            status: product.status,
            redemptionInput: product.redemption_input,
            redemptionType: product.redemption_type,
            transactionFee: product.transaction_fee,
            customAmount: formatCustomAmount(product.custom_amount),
            minimumAmount: product.minimum_amount ? Number(product.minimum_amount) : 0,
            maximumAmount: product.maximum_amount ? Number(product.maximum_amount) : 0,
            redemptionInstructions: product.redemption_instructions,
            terms: product.terms,
            partnerName: product.channel_partner,
            partnerId: product.partner_id,
            voucherDescription: product.description,
            voucherName: product.product_name,
            voucherImageUrl: product.voucher_image.includes('https')
              ? product.voucher_image
              : product.voucher_image?.length > 0
              ? `${process.env.REACT_APP_IMAGES_BASE_URL}${product.voucher_image}`
              : '',
            redemptionCountryCode: product.country,
            redemptionCurrency: product.redemption_currency
              ? product.redemption_currency
              : product.country === 'ZA'
              ? 'ZAR'
              : product.country === 'ZW' || product.country === 'US'
              ? 'USD'
              : product.country === 'NG'
              ? 'NGN'
              : product.country === 'KE'
              ? 'KES'
              : product.country === 'GH'
              ? 'GHS'
              : '',
            usdAmount:
              (product.channel_partner === 'Lipaworld' && !product.zar) ||
              product.channel_partner === 'NABU' ||
              product.channel_partner === 'Checkups COVA' ||
              product.channel_partner === 'TAP' ||
              product.channel_partner === 'DT One' ||
              product.channel_partner === 'Prepay Nation' ||
              product.country === 'US' ||
              (product.country === 'ZW' && product.usd_amount && !product.custom_amount)
                ? Number(product?.usd_amount)
                : null,
            redemptionValues:
              product.country === 'ZA' && product.zar ? [product.zar * 100] : [product.redemption_amount * 100],
            retailStatus: product.status,
            subCategories: [],
            stockQuantity: product.quantity ?? null,
          }));
        return mappedProducts;
      }
    },
  });

  useEffect(() => {
    if (countryCode && category) {
      if (deals && deals.length > 0) {
        setHeadingPlaceholder(deals[0].categories[0]);
        const newOrder = deals.sort((a, b) => {
          if (a.customAmount && !b.customAmount) {
            return -1;
          }
          if (!a.customAmount && b.customAmount) {
            return 1;
          }
          if (a.stockQuantity != null && b.stockQuantity != null) {
            return b.stockQuantity - a.stockQuantity;
          }
          return 0;
        });
        setFilteredDeals(newOrder);
      } else if (deals && deals.length === 0) {
        setFilteredDeals([]);
      }
    }
  }, [deals, countryCode, category]);

  useEffect(() => {
    if (isMobile && filteredDeals.length > 2) {
      setShowScrollButtons(true);
    } else if (!isMobile && filteredDeals.length > 4) {
      setShowScrollButtons(true);
    } else {
      setShowScrollButtons(false);
    }
  }, [filteredDeals, isMobile]);

  const checkScroll = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth - 10);
    }
  };

  const scroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = direction === 'left' ? -320 : 320;
      scrollContainerRef.current.scrollBy({
        left: scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  const handleCardClick = (product) => {
    setModalState({ isOpen: true, selectedProduct: product });
  };

  const handleCloseModal = () => {
    setModalState({ isOpen: false, selectedProduct: null });
  };

  const handleRedemptionValueChange = (productId, value) => {
    setRedemptionValues((prev) => ({
      ...prev,
      [productId]: value,
    }));
  };

  return (
    <section className={styles.lipaworld_section}>
      <div className={styles.lipaworld_header}>
        <div className={styles.lipaworld_titleContainer}>
          <h2 className={styles.lipaworld_sectionTitle}>{headingPlaceholder}</h2>
          {/* {showScrollButtons && (
            <Link
              to={`/shop?category=${category}&countryCode=${countryCode}`}
              className={styles.lipaworld_seeAllLink}
            >
              See all
            </Link>
          )} */}
        </div>
        {showScrollButtons && (
          <div className={styles.lipaworld_navigation}>
            <button
              className={`${styles.lipaworld_navButton} ${!canScrollLeft ? styles.lipaworld_navButtonDisabled : ''}`}
              onClick={() => scroll('left')}
              disabled={!canScrollLeft}
            >
              <svg width="24" height="24" viewBox="0 0 24 24">
                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
              </svg>
            </button>
            <button
              className={`${styles.lipaworld_navButton} ${!canScrollRight ? styles.lipaworld_navButtonDisabled : ''}`}
              onClick={() => scroll('right')}
              disabled={!canScrollRight}
            >
              <svg width="24" height="24" viewBox="0 0 24 24">
                <path d="M8.59 16.59L10 18l6-6-6-6-1.41 1.41L13.17 12z" />
              </svg>
            </button>
          </div>
        )}
      </div>

      <div className={styles.lipaworld_productsContainer} ref={scrollContainerRef} onScroll={checkScroll}>
        {filteredDeals.map((product) => (
          <ProductCard
            key={product.dealId}
            title={product.voucherName}
            price={`${product.redemptionCurrency} ${product.redemptionValues[0]}`}
            voucherImageUrl={product.voucherImageUrl}
            onClick={() => handleCardClick(product)}
            product={product}
            redemptionInputValue={redemptionValues[product.dealId] || product.redemptionValues[0]}
          />
        ))}
      </div>

      {modalState.isOpen && (
        <VoucherDetails
          onClose={handleCloseModal}
          product={modalState.selectedProduct}
          redemptionInputValue={
            redemptionValues[modalState.selectedProduct.dealId] || modalState.selectedProduct.redemptionValues[0] / 100
          }
          setRedemptionInputValue={(value) => handleRedemptionValueChange(modalState.selectedProduct.dealId, value)}
        />
      )}
    </section>
  );
};

export default ProductsByCategory;
