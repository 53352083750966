import React from 'react';
import ProgressBar from '../../components/checkout/ProgressBar';
import Navbar from '../../components/marketplace/nav/nav';
import CartItems from '../../components/checkout/cartitem/CartItems';

const Cart = () => (
  <>
    <Navbar />
    <ProgressBar />
    <CartItems />
  </>
);

export default Cart;
