import React from 'react';
import styles from '../marketplace/products/ProductSection.module.css';
import { useCountries } from '../../context/CountriesContext';

function DisplayedCardAmounts({ redemptionInputValue = 0, product }) {
  const countries = useCountries();

  // Get the currency rate based on the product's country
  const getCurrencyRate = () => {
    if (product.countryCode === 'ZA') {
      return countries['ZA']?.rate || 19.45;
    }
    if (product.countryCode === 'ZW' || product.countryCode === 'US' || product.countryCode === 'NG') {
      return 1;
    }
    return 1;
  };

  const currencyRate = getCurrencyRate();

  // Helper function to format currency amounts with comma separators
  const formatCurrency = (amount, currency) => {
    const formattedAmount = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Number(amount));

    return `${currency} ${formattedAmount}`;
  };

  // Get custom amount range if applicable
  const getAmountRange = (isBase = false) => {
    if (!product.customAmount) return '';

    const currency = isBase ? 'USD' : product.redemptionCurrency;
    const minAmount = isBase ? product.minimumAmount / currencyRate : product.minimumAmount;
    const maxAmount = isBase ? product.maximumAmount / currencyRate : product.maximumAmount;

    return `${formatCurrency(minAmount, currency)} - ${formatCurrency(maxAmount, currency)}`;
  };

  // Calculate the display amount
  const getDisplayAmount = () => {
    if (product.customAmount) {
      return getAmountRange();
    } else if (redemptionInputValue) {
      if (product.customAmount && product.minimumAmount != null && product.maximumAmount != null) {
        formatCurrency(redemptionInputValue / 100, product.redemptionCurrency);
      }
      return formatCurrency(redemptionInputValue / 100, product.redemptionCurrency);
    } else {
      return formatCurrency(product.redemptionValues[0] / 100, product.redemptionCurrency);
    }
  };

  // Calculate the USD equivalent amount
  const getUsdAmount = () => {
    const isUsdAmountValid = product.usdAmount != null && !Number.isNaN(product.usdAmount);
    if (product.customAmount) {
      return getAmountRange(true);
    }
    if (isUsdAmountValid) {
      return formatCurrency(product.usdAmount, 'USD');
    }
    if (redemptionInputValue) {
      return formatCurrency(redemptionInputValue / currencyRate / 100, 'USD');
    }

    return formatCurrency(product.redemptionValues[0] / 100 / currencyRate, 'USD');
  };

  return (
    <div className={styles.amountRedemption}>
      <strong>
        {getDisplayAmount()}
        {'   '}
      </strong>
      {(product.categories[0] === 'Education' || product.customAmount) && <br />}
      {product.redemptionCurrency !== 'USD' && <span className={styles.amountPurchase}>({getUsdAmount()})</span>}
    </div>
  );
}

export default DisplayedCardAmounts;
