// import { useState } from 'react';
// import { useQuery } from '@tanstack/react-query';
import mixpanel from 'mixpanel-browser';
import { calculateRedemptionValue } from './calculateRedemptionValue';
// import { ICartItem } from '@/types/ICart';
// import { IUser } from '@/types/IUser';

// export const useSendPins = () => {
//   const { isLoading, isError, data, error } = useQuery({
//     queryKey: ['sendPins'],
//     queryFn: async () => {
//       const { data }: { data: IData } = await fetchData('https://api.example.com/sendPins');
//       return data;
//     },
//   });

//   return { isLoading, isError, data, error };
// };

// const pinText = (itemVoucher) => {
//   if (itemVoucher.length > 1) {
//     return `*PINs: ${itemVoucher.join(', ')}*`;
//   } else {
//     return `*PIN: ${itemVoucher[0]}*`;
//   }
// };

export const generateMessage = (item, user, messageType) => {
  const itemVouchers = item.vouchers?.map((itemVoucher) => `*PIN: ${itemVoucher.pin}*`) || [];
  const redemptionValue = calculateRedemptionValue(item).toFixed(2);
  const recipientName = item.productRecipient?.name ?? '';
  const recipientSurname = item.productRecipient?.surname ?? '';
  const terms = item.deal.terms;
  const redemptionInstructions = item.deal.redemptionInstructions;

  const termsText = terms ? ` *Terms and conditions:* ${terms}\n\n` : '';
  const redemptionInstructionsText = redemptionInstructions
    ? ` *Redemption Instructions:* ${redemptionInstructions}\n\n`
    : '';

  const message = `Congratulations ${recipientName} ${recipientSurname}. You have received ${
    item.quantity === 1 ? 'a ' : ''
  }digital ${messageType} voucher${item.quantity > 1 ? 's' : ''} from ${user.name} ${user.surname}.\n\n${
    item.quantity
  } x ${item.deal.redemptionCurrency}${redemptionValue} *${item.deal.voucherName}* from ${
    item.deal.merchantName
  }.\n\n ${itemVouchers.join(
    '\n',
  )} \n\n${termsText}${redemptionInstructionsText} Terms of use available on www.lipaworld.com`.trim();

  return message;
};

export const sendMessage = (item, user, messageType, channel) => {
  mixpanel.track(`Sent ${channel} message to a recipient with ${messageType} voucher${item.quantity > 1 ? 's' : ''}`);

  if (item.productRecipient && item.redemptionInputValue) {
    const message = generateMessage(item, user, messageType);
    let shareBlock;

    if (channel === 'whatsapp') {
      shareBlock = `https://wa.me/${item.productRecipient.mobileNumber.substring(1, 20)}?text=${message}`;
    } else if (channel === 'sms') {
      shareBlock = `sms:${item.productRecipient.mobileNumber}?body=${encodeURIComponent(message)}`;
    }
    // location.href = shareBlock ?? '';
    window.open(shareBlock, '_self');
  }
};

export const sendPinWhatsAppMessage = (item, user) => {
  sendMessage(item, user, 'pin', 'whatsapp');
};

export const sendTopUpWhatsAppMessage = (item, user) => {
  sendMessage(item, user, 'topup', 'whatsapp');
};

export const sendPinSmsMessage = (item, user) => {
  sendMessage(item, user, 'pin', 'sms');
};

export const sendTopUpSmsMessage = (item, user) => {
  sendMessage(item, user, 'topup', 'sms');
};

export const sendPinEmailMessage = (item, user) => {
  mixpanel.track('Sent email message to a recipient with pin voucher');
  const message = generateMessage(item, user, 'pin');
  const mailto = `mailto:?subject=You have received a voucher from ${user.name} ${
    user.surname
  }&body=${encodeURIComponent(message)}`;
  window.open(mailto, '_blank');
};

export const sendTopUpEmailMessage = (item, user) => {
  mixpanel.track('Sent email message to a recipient with topup voucher');
  const message = generateMessage(item, user, 'topup');
  const mailto = `mailto:?subject=You have received a top-up voucher from ${user.name} ${
    user.surname
  }&body=${encodeURIComponent(message)}`;
  window.open(mailto, '_blank');
};
