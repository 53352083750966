import React, { useState, useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import styles from './VoucherProcessStatus.module.css';
// import { logEvent } from '@/utils/logEvent';
import CircularProgress from '@mui/material/CircularProgress';

const VoucherProcessStatus = ({ payment }) => {
  const SUCCESS_STATUS = 'succeeded';
  const [voucherMessageBody, setVoucherMessageBody] = useState('');
  const [intentStatus, setIntentStatus] = useState('');

  useEffect(() => {
    if (payment?.purchase_units[0]?.payments?.captures[0]?.status === 'COMPLETED') {
      setIntentStatus(SUCCESS_STATUS);
      setVoucherMessageBody(
        `Payment of ${payment.purchase_units[0].amount.currency_code}${Number(
          payment.purchase_units[0].amount.value,
        ).toFixed(2)} succeeded. Vouchers being processed.`,
      );
      mixpanel.track(`Successful Payment`);
      // logEvent('payment', 'purchase_success', payment.purchase_units[0].amount.value ?? 0, 'purchase_success');
    }
  }, [payment]);

  return (
    <div className={styles.contentBody}>
      {intentStatus === SUCCESS_STATUS && voucherMessageBody && (
        <>
          <div
            role="alert"
            style={{
              textAlign: 'center',
            }}
          >
            {voucherMessageBody}
          </div>
          <div>
            <br />
            <br />
            <CircularProgress size={12} color="success" />
          </div>
        </>
      )}
    </div>
  );
};

export default VoucherProcessStatus;
