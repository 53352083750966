import React, { useState, useEffect } from 'react';
import Link, { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import { useAuth } from '../../../context/AuthContext';

import CartSummary from './CartSummary';
import { useCart, useDispatchCart } from '../../../context/CartContext';
import { useTransaction, useDispatchTransaction } from '../../../context/TransactionContext';
import { useRecipients } from '../../../context/RecipientsContext';
import { useDispatchCountries } from '../../../context/CountriesContext';
import calculateProcessingFee from '../../../utils/calculateProcessingFee';

import styles from './cartitem.module.css';

const Cart = () => {
  const { authState } = useAuth();
  const { cartItems } = useCart();
  const dispatchCart = useDispatchCart();
  const navigate = useNavigate();

  const dispatchCountries = useDispatchCountries();
  const dispatchTransaction = useDispatchTransaction();
  const recipients = useRecipients();
  const cart = useCart();
  const transaction = useTransaction();
  const [errorMessage, setErrorMessage] = useState(null);
  const [searchParams, setSearchParams] = useState(null);
  const [cartTotalAmount, setCartTotalAmount] = useState('0.00');
  const [cartTransactionFee, setCartTransactionFee] = useState('0.00');
  const [cartProcessingFee, setCartProcessingFee] = useState('0.00');
  const [cartDiscounts, setCartDiscounts] = useState(0);
  const [cartTotalAmountNumeric, setCartTotalAmountNumeric] = useState(0);
  const [totalCowryValue, setTotalCowryValue] = useState(0);
  const [cartSubtotal, setCartSubtotal] = useState('0.00');

  useEffect(() => {
    if (!authState.isAuthenticated) {
      navigate('/signin');
    }
  }, [authState.isAuthenticated, navigate]);

  const useExchangeRate = () => {
    const queryKey = ['exchangeRate'];
    const queryFn = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${process.env.REACT_APP_API_CONFIGURATIONS_URL}/v1/rate/latest`,
          timeout: 30000,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        });

        return response.data.rate;
      } catch (error) {
        console.error('Exchange Rate Error:', {
          name: error.name,
          message: error.message,
          code: error.code,
          config: error.config,
          response: error.response
            ? {
                status: error.response.status,
                data: error.response.data,
                headers: error.response.headers,
              }
            : null,
        });

        let errorMessage = 'Failed to fetch exchange rate.';

        if (axios.isCancel(error)) {
          errorMessage = 'Request was cancelled';
        } else if (error.code === 'ECONNABORTED') {
          errorMessage = 'Request timed out. Please check your internet connection.';
        } else if (error.response) {
          switch (error.response.status) {
            case 400:
              errorMessage = 'Bad Request: Invalid exchange rate request';
              break;
            case 401:
              errorMessage = 'Unauthorized: Please log in again';
              break;
            case 403:
              errorMessage = 'Forbidden: You do not have permission to access exchange rates';
              break;
            case 404:
              errorMessage = 'Exchange rate service not found';
              break;
            case 500:
              errorMessage = 'Exchange rate service is currently unavailable';
              break;
            default:
              errorMessage = `Unexpected error: ${error.response.status}`;
          }
        } else if (error.request) {
          errorMessage = 'No response received from exchange rate service. Check your network connection.';
        }

        throw new Error(errorMessage);
      }
    };

    const {
      data: exchangeRate,
      isLoading: isExchangeRateLoading,
      isError: isExchangeRateError,
      error: exchangeRateError,
    } = useQuery({ queryKey, queryFn });

    return {
      exchangeRate,
      isExchangeRateLoading,
      isExchangeRateError,
      exchangeRateError,
    };
  };

  const { exchangeRate, isExchangeRateLoading, isExchangeRateError, exchangeRateError } = useExchangeRate();

  if (isExchangeRateError) {
    setErrorMessage('Failed to fetch rate. Please try again later.');
  }

  const useVery = () => {
    const queryKey = ['very'];
    const queryFn = async () => {
      if (!authState?.id) {
        return {
          very: 'User not found.',
          isVeryLoading: false,
          veryError: null,
        };
      }
      const res = await fetch(`${process.env.REACT_APP_API_USERS_V1_URL}/very?userId=${authState?.id}`);
      const data = await res.json();
      return data.message;
    };
    const {
      data: very,
      isLoading: isVeryLoading,
      isError: isVeryError,
      error: veryError,
    } = useQuery({ queryKey, queryFn });
    return { very, isVeryLoading, isVeryError, veryError };
  };

  const { very, isVeryLoading, isVeryError, veryError } = useVery();

  useEffect(() => {
    if (veryError) {
      setErrorMessage('Failed to fetch status. Please try again later.');
    } else if (very === 'User not found.') {
      // mixpanel.track(`User not found`);
      // setErrorMessage('User registration is required. Please visit the register page to sign-up.');
    } else if (very === 'User not verified.') {
      // mixpanel.track(`User not verified`);
      // setErrorMessage('User verification is required. Please visit the My Details page to verify your account.');
    } else if (very === 'completed') {
      // setErrorMessage('Your verification still in progress. Please try checkout later.');
    } else if (very !== 'completed' && very !== 'approved') {
      // mixpanel.track(`User verification status: ${very}`);
      // setErrorMessage(`Your user verification status is ${very}. Contact support for assistance.`);
    }
  }, [very, veryError, cart]);

  const fetchExchangeRate = async () => {
    try {
      const rate = exchangeRate.markedup_rate;
      dispatchCountries({
        type: 'UPDATE_EXCHANGE_RATE',
        payload: exchangeRate,
      });
      dispatchTransaction({
        type: 'SET_USDZAR_RATE',
        payload: { exchangeRate: rate, exchangeRateId: exchangeRate.id },
      });
      dispatchCart({
        type: 'UPDATE_CURRENCY_RATE',
        payload: {
          rate,
          sourceCurrency: 'ZAR',
          destinationCurrency: 'USD',
          rateId: exchangeRate.id,
        },
      });
    } catch (error) {
      // mixpanel.track(`Failed to fetch exchange rate`);
      setErrorMessage('Failed to fetch the latest exchange rate. Please try again later.');
      return;
    }
  };

  useEffect(() => {
    if (exchangeRate && exchangeRate.markedup_rate) {
      fetchExchangeRate();
    }
  }, [exchangeRate]);

  const updateSearchParams = () => {
    if (cart && cart.cartItems) {
      setErrorMessage(null);
      const params = new URLSearchParams(window.location.search);
      setSearchParams(params);
    }
  };

  useEffect(() => {
    updateSearchParams();
  }, [cart]);

  useEffect(() => {
    if (cart && cart.cartItems) {
      if (cart.cartItems.length === 0) {
        // mixpanel.track(`Empty Cart`);
        setErrorMessage(`Your cart is empty.`);
        return;
      }
    }
  }, [cart?.cartItems, cart]);

  const getRedemptionAmount = (item) => {
    if (item.deal.customAmount && item.redemptionInputValue) {
      return item?.redemptionInputValue * 100;
    } else {
      return item.deal.redemptionValues[0];
    }
  };

  useEffect(() => {
    if (cart?.cartItems && exchangeRate?.markedup_rate) {
      let totalTransactionFee = 0;
      const subTotalAmount = cart.cartItems.reduce((acc, item) => {
        let itemTotal = 0;
        const fxRate = item.deal?.usdAmount ? 1 : exchangeRate?.markedup_rate;

        if (item.deal?.usdAmount !== null && item.deal?.usdAmount !== undefined && item.deal?.usdAmount > 0) {
          itemTotal = item.quantity * item.deal.usdAmount;
        } else if (item.deal.redemptionCurrency === 'USD') {
          const redemptionAmount = getRedemptionAmount(item) || 0;
          itemTotal = item.quantity * (redemptionAmount / 100);
        } else {
          const redemptionAmount = getRedemptionAmount(item) || 0;
          itemTotal = (item.quantity * (redemptionAmount / 100)) / (fxRate || 1);
        }
        const transactionFeeDecimal = Number(item.deal.transactionFee);
        const transactionFeeItem = itemTotal * transactionFeeDecimal;
        totalTransactionFee += transactionFeeItem;
        return acc + itemTotal;
      }, 0);

      setCartSubtotal(subTotalAmount.toFixed(2));
      const transactionFeeAmount = totalTransactionFee.toFixed(2);
      setCartTransactionFee(transactionFeeAmount);

      const processingFee = calculateProcessingFee(Number(subTotalAmount), totalTransactionFee, cart.cartItems);
      setCartProcessingFee(processingFee);

      const totalAmount = Number(subTotalAmount) + Number(transactionFeeAmount) + Number(processingFee) - cartDiscounts;

      if (totalAmount > 9999) {
        setErrorMessage('The total amount is over $9,999. Please remove some items from the cart to continue.');
        return;
      }
      setCartTotalAmountNumeric(totalAmount);
      setCartTotalAmount(totalAmount.toFixed(2));

      sessionStorage.setItem('cartTotalAmount', JSON.stringify(totalAmount));
      sessionStorage.setItem('cart', JSON.stringify(cart));
    }
  }, [cart, exchangeRate?.markedup_rate, cartDiscounts]);

  useEffect(() => {
    dispatchCart({
      type: 'UPDATE_CART_AMOUNTS',
      payload: {
        subtotal: cartSubtotal,
        totalAmount: cartTotalAmount,
        transactionFee: cartTransactionFee,
        processingFee: cartProcessingFee,
        discounts: cartDiscounts,
      },
    });
    updateTransactionAmounts(cartProcessingFee, cartTotalAmount, cartTransactionFee, cartDiscounts, cartSubtotal);
  }, [cartSubtotal, cartTotalAmount, cartTransactionFee, cartProcessingFee, cartDiscounts]);

  useEffect(() => {
    sessionStorage.setItem('recipients', JSON.stringify(recipients));
  }, [recipients]);

  const updateTransactionAmounts = (processFee, cartTotalAmount, cartTransactionFee, cartDiscounts, subtotal) => {
    dispatchTransaction({
      type: 'UPDATE_AMOUNTS',
      payload: {
        subtotal: Number(subtotal),
        processingFee: Number(processFee),
        cartTotalAmount: Number(cartTotalAmount),
        transactionFee: Number(cartTransactionFee),
        cartDiscounts: cartDiscounts,
      },
    });
  };

  const updateRecipients = (recipients, user) => {
    if (recipients.length > 0) {
      if (recipients[0].id) {
        dispatchTransaction({
          type: 'UPDATE_PARTIES',
          payload: {
            senderId: recipients[0].senderId,
            recipientIds: [recipients[0].id],
          },
        });
      } else {
        dispatchTransaction({
          type: 'UPDATE_PARTIES',
          payload: {
            senderId: user?.id ?? '',
            recipientIds: [`${recipients[0].name} ${recipients[0].surname} ${recipients[0].mobileNumber}`],
          },
        });
      }
    }
  };

  const removeFromCartHandler = (cartItemId) => {
    try {
      if (cartItemId) {
        console.log(cartItemId);
        dispatchCart({
          type: 'REMOVE_CART_ITEM',
          payload: cartItemId,
        });
        setCartDiscounts(0);
      } else {
        setErrorMessage('Missing important order details.');
      }
    } catch (error) {
      setErrorMessage('Something went wrong. Please try again later.');
    }
  };

  useEffect(() => {
    const fetchTransactionId = async () => {
      const url = `${process.env.REACT_APP_API_CONFIGURATIONS_URL}/v1/transactions/transactionid`;
      try {
        const response = await axios({
          method: 'POST',
          url,
          timeout: 30000,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        });

        if (typeof sessionStorage !== 'undefined') {
          sessionStorage.setItem('transactionId', response.data.transactionId);
        }
        dispatchTransaction({
          type: 'SET_TRANSACTION_ID',
          payload: response.data.transactionId,
        });
      } catch (error) {
        console.error('Transaction ID Error:', {
          name: error.name,
          message: error.message,
          code: error.code,
          config: error.config,
          response: error.response
            ? {
                status: error.response.status,
                data: error.response.data,
                headers: error.response.headers,
              }
            : null,
        });

        let errorMessage = 'Failed to generate transaction ID.';

        if (axios.isCancel(error)) {
          errorMessage = 'Request was cancelled';
        } else if (error.code === 'ECONNABORTED') {
          errorMessage = 'Request timed out. Please check your internet connection.';
        } else if (error.response) {
          switch (error.response.status) {
            case 400:
              errorMessage = 'Bad Request: Invalid transaction request';
              break;
            case 401:
              errorMessage = 'Unauthorized: Please log in again';
              break;
            case 403:
              errorMessage = 'Forbidden: You do not have permission to create transactions';
              break;
            case 404:
              errorMessage = 'Transaction service not found';
              break;
            case 500:
              errorMessage = 'Transaction service is currently unavailable';
              break;
            default:
              errorMessage = `Unexpected error: ${error.response.status}`;
          }
        } else if (error.request) {
          errorMessage = 'No response received from transaction service. Check your network connection.';
        }

        setErrorMessage(errorMessage);
        toast.error(errorMessage, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    };

    fetchTransactionId();
  }, []);

  useEffect(() => {
    const fetchCartId = async () => {
      const url = `${process.env.REACT_APP_API_CONFIGURATIONS_URL}/v1/cart/cartid`;
      try {
        const response = await axios({
          method: 'POST',
          url,
          timeout: 30000,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        });

        if (typeof sessionStorage !== 'undefined') {
          sessionStorage.setItem('cartId', response.data.cartId);
        }
        dispatchCart({
          type: 'SET_CART_ID',
          payload: response.data.cartId,
        });
      } catch (error) {
        console.error('Cart ID Error:', {
          name: error.name,
          message: error.message,
          code: error.code,
          config: error.config,
          response: error.response
            ? {
                status: error.response.status,
                data: error.response.data,
                headers: error.response.headers,
              }
            : null,
        });

        let errorMessage = 'Failed to generate cart ID.';

        if (axios.isCancel(error)) {
          errorMessage = 'Request was cancelled';
        } else if (error.code === 'ECONNABORTED') {
          errorMessage = 'Request timed out. Please check your internet connection.';
        } else if (error.response) {
          switch (error.response.status) {
            case 400:
              errorMessage = 'Bad Request: Invalid cart request';
              break;
            case 401:
              errorMessage = 'Unauthorized: Please log in again';
              break;
            case 403:
              errorMessage = 'Forbidden: You do not have permission to create carts';
              break;
            case 404:
              errorMessage = 'Cart service not found';
              break;
            case 500:
              errorMessage = 'Cart service is currently unavailable';
              break;
            default:
              errorMessage = `Unexpected error: ${error.response.status}`;
          }
        } else if (error.request) {
          errorMessage = 'No response received from cart service. Check your network connection.';
        }

        setErrorMessage(errorMessage);
        toast.error(errorMessage, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    };

    fetchCartId();
  }, []);

  useEffect(() => {
    sessionStorage.setItem('transaction', JSON.stringify(transaction));
  }, [transaction]);

  useEffect(() => {
    sessionStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const checkoutHandler = () => {
    updateTransactionAmounts(cartProcessingFee, cartTotalAmount, cartTransactionFee, cartDiscounts, cartSubtotal);
    sessionStorage.setItem('cart', JSON.stringify(cart));
    updateRecipients(recipients, authState);
    if (!name) {
      router.push(`/login?return_url=/payment?${searchParams && searchParams.toString()}&discounts=${cartDiscounts}`);
      return;
    }
    if (name) {
      // mixpanel.track(`Voucher Purchase Initiated`);
      logEvent('cart', 'cart_loaded', cartTotalAmount ? Number(cartTotalAmount) : 0, 'cart_loaded');
      router.push(`/payment?discounts=${cartDiscounts}`);
    }
  };

  useEffect(() => {
    const cowryItemsInCart = cart?.cartItems.filter((item) => item.deal.merchantName === 'Cowry') || [];
    const totalCowryValue = cowryItemsInCart.reduce((sum, item) => {
      return sum + (item.redemptionInputValue || 0);
    }, 0);
    setTotalCowryValue(totalCowryValue);
  }, [cart]);

  const zaItemsInCart = cart?.cartItems.filter((item) => item.deal.countryCode === 'ZA');
  const zaItem = zaItemsInCart && zaItemsInCart.length > 0 ? true : false;

  const cartItemsCount = cart?.cartItems.length || 0;

  if (isVeryLoading || isExchangeRateLoading) {
    return (
      <div className={styles.cartContainer}>
        <div className={styles.cartMainSection}>
          <CircularProgress size={24} color="success" />
        </div>
      </div>
    );
  }

  const allowedToPurchase =
    (!errorMessage &&
      !isVeryLoading &&
      !isExchangeRateLoading &&
      !isExchangeRateError &&
      !isVeryError &&
      very === 'approved') ||
    (!errorMessage && !isExchangeRateLoading && !isExchangeRateError);

  const notAllowedToPurchase =
    !isVeryLoading && !isVeryError && very !== 'approved' && cart && cart.cartItems && cart.cartItems.length > 0;

  console.log('very', very);

  return (
    <div className={styles.cartContainer}>
      <div className={styles.cartMainSection}>
        <div className={styles.cartHeader}>
          <h1>Cart</h1>
          {cartItems?.length > 0 && <span className={styles.itemCount}>{cartItems?.length} items</span>}
        </div>

        {notAllowedToPurchase && (
          <div className={styles.cartItemsList}>
            <p style={{ backgroundColor: 'lightgreen', padding: '10px', borderRadius: '5px' }}>
              Checkout can only be initiated when you are verified. Please check your email for a verification link sent
              to you during the sign up process.
            </p>
          </div>
        )}

        <div className={styles.cartItemsList}>
          {cartItems &&
            cartItems.map((item) => (
              <div key={item.cartItemId} className={styles.cartItem}>
                <div className={styles.leftSection}>
                  <div className={styles.itemImageContainer}>
                    <img src={item.deal.voucherImageUrl} alt={item.deal.voucherName} className={styles.itemImage} />
                  </div>
                  <div className={styles.itemDetails}>
                    <span className={styles.itemName}>{item.deal.voucherName}</span>
                    <span className={styles.itemPrice}>
                      {item.deal.redemptionCurrency} {item.redemptionInputValue.toFixed(2)}
                    </span>
                    {/* <div className={styles.quantityControl}>
                      <button className={styles.quantityButton}>
                        <img
                          src="/img/shop/minus-circle.svg"
                          className={styles.quantityButtonimg}
                        />
                      </button>
                      <input
                        type="text"
                        value="1"
                        className={styles.quantityInput}
                        readOnly
                      />
                      <button className={styles.quantityButton}>
                        <img
                          src="/img/shop/add-circle.svg"
                          className={styles.quantityButtonimg}
                        />
                      </button>
                    </div> */}
                  </div>
                </div>
                <div className={styles.rightSection}>
                  <button className={styles.deleteButton} onClick={() => removeFromCartHandler(item.cartItemId)}>
                    <img className={styles.trashIcon} src="/img/shop/trash.svg" />
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>

      {notAllowedToPurchase ? <></> : <CartSummary buttonText={'Continue to Recipient'} />}
    </div>
  );
};

export default Cart;
