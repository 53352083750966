import { Lists } from "../utils/data/mockLists";

import { useReducer, useContext, createContext } from "react";

// Create the lists state context with an empty array as initial value
const ListsStateContext = createContext([]);

// Create the lists dispatch context with a dummy function as initial value
const ListsDispatchContext = createContext(() => null);

// Define the reducer function for the lists context
const reducer = (state, action) => {
  switch (action.type) {
    // case 'CREATE_RECIPIENT':
    //   // Generate a random id for the new list
    //   // action.payload.id = uuid();
    //   // Return a new state with the new list added to the array
    //   return [...state, action.payload];
    // case 'ADD_LISTS':
    //   // Generate a random id for the new list
    //   // action.payload.id = uuid();
    //   // Return a new state with the new list added to the array
    //   return [...action.payload];
    case "ADD_LIST":
      // Check if the list already exists in the state by id
      const exists = state.some((r) => r.listName === action.payload.listName);
      // If not, return a new state with the list added to the array
      if (!exists) {
        return [action.payload, ...state];
      } else {
        return state;
      }
    case "ADD_LISTS":
      const lists = [];
      action.payload.forEach((list) => {
        // Check if the list already exists in the state by id
        const exists = state.some((r) => r.listName === list.listName);
        // If not, return a new state with the list added to the array
        if (!exists) {
          lists.push(list);
        }
      });
      if (!action.payload) {
        return state;
      }
      return [...lists, ...state];

    case "ADD_VOUCHER_TO_LIST": {
      const index = state.findIndex((list) => list.id === action.payload.id);
      const itemIndex = state[index].items.findIndex(
        (item) => item.dealId === action.payload.item.dealId
      );
      if (itemIndex === -1) {
        state[index].items.push(action.payload.item);
      }
      return state;
    }
    case "UPDATE_LIST":
      const index = state.findIndex((list) => list.id === action.payload.id);
      state[index] = action.payload;
      return state;
    // case 'SELECT_RECIPIENT':
    //   // CHECK THE Action.payload, in state, move the supplied payload to the begining of the state array
    //   // Find the index of the list in the state by id
    //   const indexSelected = state.findIndex(
    //     (r) => r.listName === action.payload.listName
    //   );
    //   const newState = state.filter(
    //     (r) => r.listName !== action.payload.listName
    //   );
    //   // If found, return a new state with the list updated with the new data
    //   if (indexSelected !== -1) {
    //     return [action.payload, ...newState];
    //   } else {
    //     return state;
    //   }

    case "DELETE_VOUCHER_FROM_LIST": {
      const index = state.findIndex(
        (list) => list.listName === action.payload.listName
      );
      state[index].items = state[index].items.filter(
        (item) => item.dealId !== action.payload.dealId
      );
      return [...state];
      // TODO: Add some logic to delete the list from a database or an API
      // Return a new state with the list filtered out by id from the array
      // return state.filter((r) => r.listName !== action.payload);
    }
    case "DELETE_LIST":
      // TODO: Add some logic to delete the list from a database or an API
      // Return a new state with the list filtered out by id from the array
      return state.filter((r) => r.listName !== action.payload);
    default:
      throw new Error(`Unknown action: ${JSON.stringify(action)}`);
  }
};

const defaultLists = Lists;

export const ListsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultLists);
  return (
    <ListsDispatchContext.Provider value={dispatch}>
      <ListsStateContext.Provider value={state}>
        {children}
      </ListsStateContext.Provider>
    </ListsDispatchContext.Provider>
  );
};

export const useLists = () => useContext(ListsStateContext);

export const useDispatchLists = () => useContext(ListsDispatchContext);
