import React from 'react';
import { useNavigate } from 'react-router-dom'
import styles from './BrandGridView.module.css';

const BrandCard = ({ title, price }) => (
  <div className={styles.lipaworld_productCard}>
    <div className={styles.lipaworld_productImage}>
    </div>
    <div className={styles.lipaworld_productInfo}>
      <h3 className={styles.lipaworld_productTitle}>{title}</h3>
    </div>
  </div>
);

const BrandGridView = ({ onBack }) => {
  const navigate = useNavigate();
  const products = [
    { id: 1, title: 'MTN Voucher', price: 'NGN 1000' },
    { id: 2, title: 'MTN Voucher', price: 'NGN 1000' },
    { id: 3, title: 'MTN Voucher', price: 'NGN 1000' },
    { id: 4, title: 'MTN Voucher', price: 'NGN 1000' },
    { id: 5, title: 'MTN Voucher', price: 'NGN 1000' },
    { id: 6, title: 'MTN Voucher', price: 'NGN 1000' },
    { id: 7, title: 'MTN Voucher', price: 'NGN 1000' },
    { id: 8, title: 'MTN Voucher', price: 'NGN 1000' },
    { id: 9, title: 'MTN Voucher', price: 'NGN 1000' },
    { id: 10, title: 'MTN Voucher', price: 'NGN 1000' },
  ];

  const handleBackClick = () => {
    navigate('/marketplace');
  };

  return (
    <div className={styles.lipaworld_container}>
      <button className={styles.lipaworld_backButton} onClick={handleBackClick}>
        <svg width="24" height="24" viewBox="0 0 24 24">
          <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/>
        </svg>
        Back
      </button>

      <h1 className={styles.lipaworld_title}>Brands</h1>

      <div className={styles.lipaworld_productGrid}>
        {products.map(product => (
          <BrandCard key={product.id} {...product} />
        ))}
      </div>
    </div>
  );
};

export default BrandGridView;