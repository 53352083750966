import React, { useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import styles from "../styles/emailverification.module.css";

const PhoneVerification = () => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [isValid, setIsValid] = useState(null);
  const inputRefs = useRef([]);
  const navigate = useNavigate();

  const handleOtpChange = (index, e) => {
    const value = e.target.value;
    if (!/^\d*$/.test(value)) return; // Only allow numbers

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    setIsValid(null);

    // Move to next input if value is entered
    if (value !== "" && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace") {
      if (otp[index] === "" && index > 0) {
        // Move to previous input if current is empty
        inputRefs.current[index - 1].focus();
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
      }
    }
  };

  const handleVerifyCode = () => {
    if (otp.join("") === "123456") {
      setIsValid(true);
      navigate("/marketplace");
    } else {
      setIsValid(false);
    }
  };

  const handleBack = () => {
    navigate("/addphonenumber");
  };

  return (
    <div className={styles.container}>
      <div className={styles.formWrapper}>
        <div className={styles.logoContainer}>
          <Link to="/">
            <img
              src="/img/auth/logo-black2.png"
              alt="Lipaworld logo"
              className={styles.logo}
            />
          </Link>
        </div>

        {/* Verify Email */}
        <div className={styles.verifyEmail}>
          <h1>Verify your Number</h1>
          <p>
            We sent a verification code to +234 90 202 33 3892. To verify your
            email address, please check your inbox and enter the code below.
          </p>

          {/* OTP Input */}
          <div className={styles.otpInput}>
            {Array.from({ length: 6 }).map((_, index) => (
              <input
                key={index}
                ref={(el) => (inputRefs.current[index] = el)}
                type="text"
                maxLength={1}
                value={otp[index]}
                onChange={(e) => handleOtpChange(index, e)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                className={`${styles.otpDigit} ${
                  isValid === true
                    ? styles.otpValid
                    : isValid === false
                    ? styles.otpInvalid
                    : ""
                }`}
              />
            ))}
          </div>

          <div className={styles.buttonContainer}>
            <button className={styles.backButton} onClick={handleBack}>
              ← Back
            </button>
            <button className={styles.verifyButton} onClick={handleVerifyCode}>
              Verify Code
            </button>
          </div>

          {/* Resend Email */}
          <p className={styles.resendEmail}>
            Didn't receive a text?{" "}
            <a href="#" className={styles.resendLink}>
              Try again
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PhoneVerification;
