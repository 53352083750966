import React from "react";
import { useNavigate, Link } from "react-router-dom";
import styles from "../styles/emailverification.module.css";
import styles2 from "../styles/signup.module.css";

const PasswordResetConfirm = () => {
  // const [otp, setOtp] = useState("");
  // const [isValid, setIsValid] = useState(null);
  // const [email, setEmail] = useState("");
  const navigate = useNavigate();

  // const handleOtpChange = (e) => {
  //   const value = e.target.value;
  //   setOtp(value);
  //   setIsValid(null);
  // };

  const handleVerifyCode = () => {
    navigate("/signin");
  };

  return (
    <div className={styles.container}>
      <div className={styles.formWrapper}>
        <div className={styles.logoContainer}>
          <Link to="/">
            <img
              src="/img/auth/logo-black2.png"
              alt="Lipaworld logo"
              className={styles.logo}
            />
          </Link>
        </div>

        <div className={styles.verifyEmail}>
          <h1>New password reset sucessfully</h1>
          <p>
            You have sucessfully reset your password, please use this new
            password when signing in.
          </p>
          <br />
        </div>

        <div className={styles2.inputGroup}>
          <button
            type="button"
            className={styles2.signupButton}
            onClick={handleVerifyCode}
          >
            Continue to Sign In
          </button>
        </div>
      </div>
    </div>
  );
};

export default PasswordResetConfirm;
