import React from 'react';
import styles from './PaymentPage.module.css';
import CartSummary from '../cartitem/CartSummary';
import PaypalBlock from './PaypalBlock';

const PaymentPage = () => {
  return (
    <div className={styles.paymentContainer}>
      <div className={styles.paymentMainSection}>
        <h1>Payment Methods</h1>
        <PaypalBlock />
      </div>
      <CartSummary />
    </div>
  );
};

export default PaymentPage;
