import React, { useState } from 'react';
import Navbar from '../../components/marketplace/nav/nav';
import PaymentFailPage from '../../components/checkout/status/PaymentFailedPage';

const Fail = () => (
  <>
    <Navbar />
    <PaymentFailPage />
  </>
);

export default Fail;
