import React from 'react';
import { toast } from 'react-toastify';
import { useDispatchLists, useDispatchCart } from '../../context';
import { useNavigate, Link } from 'react-router-dom';
import styles from './CartButtons.module.scss';

// interface ICartButtonsProps {
//   deal: IVoucher;
//   quantity: number;
//   redemptionInputValue?: number;
//   isHistory?: boolean;
//   disabled?: boolean;
//   productRecipient?: IRecipient | null;
//   setProductRecipient: Dispatch<IRecipient | null>;
//   setQuantity: Dispatch<number>;
//   includeProcessingFee?: boolean;
// }

function CartButtons({
  deal,
  quantity,
  // setQuantity,
  redemptionInputValue,
  isHistory,
  // productRecipient,
  // setProductRecipient,
  includeProcessingFee,
  onClose,
}) {
  const dispatchLists = useDispatchLists();
  const dispatchCart = useDispatchCart();
  const navigate = useNavigate();

  const addtoCartHandler = (deal, amount) => {
    // if (!user?.name) {
    //   toast.info("Login or register to proceed", {
    //     position: toast.POSITION.BOTTOM_LEFT,
    //   });
    //   navigate(
    //     `/login?return_url=${encodeURIComponent(window.location.pathname)}`
    //   );
    //   return;
    // }

    // if (!productRecipient && deal.redemptionType !== "DONATION") {
    //   toast.info("Create a recipient before adding to cart.", {
    //     position: toast.POSITION.BOTTOM_LEFT,
    //   });
    //   navigate(
    //     `/create-recipient?return_url=${encodeURIComponent(
    //       window.location.pathname
    //     )}`
    //   );
    //   return;
    // }

    if (deal.customAmount && (!amount || amount > Number(deal.maximumAmount) || amount < Number(deal.minimumAmount))) {
      toast.warn(
        `Amount should be between ${deal.redemptionCurrency}${deal.minimumAmount} and ${deal.redemptionCurrency}${deal.maximumAmount}.`,
        {
          position: toast.POSITION.BOTTOM_LEFT,
        },
      );
      return;
    }

    const allCartDetails = () => {
      return amount && quantity && deal;
    };

    try {
      if (allCartDetails()) {
        const uniqueId = Date.now();

        dispatchCart({
          type: 'ADD_CART_ITEM',
          payload: {
            cartItemId: `${deal.dealId}-${amount}-${uniqueId}`,
            deal: deal,
            productRecipient: null,
            quantity,
            redemptionInputValue: amount,
            voucherId: deal.dealId,
            includeProcessingFee,
          },
        });

        toast.success(
          <div>
            Added.
            <Link to="/checkout" style={{ marginLeft: '10px', color: '#0fbc49' }}>
              Go to Cart
            </Link>
          </div>,
          { position: toast.POSITION.BOTTOM_LEFT },
        );
        // setProductRecipient(null);
        // setQuantity(1);
        onClose();
      } else {
        toast.error('Missing important order details.', {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (error) {
      // console.error(error);
      toast.error('Something went wrong. Please try again later.', {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  const resendHandler = () => {
    addtoCartHandler(deal, redemptionInputValue ? redemptionInputValue : deal.redemptionValues[0]);
    navigate(`/cart`);
  };

  const loginRedirectHandler = () => {
    navigate(`/login/?return_url=${encodeURIComponent(window.location.pathname)}`);
  };

  const listItemClick = (e, list) => {
    dispatchLists({
      type: 'ADD_VOUCHER_TO_LIST',
      payload: { id: list.id, item: deal },
    });

    toast.success(`Voucher added ${list.listName}`, {
      position: toast.POSITION.BOTTOM_LEFT,
    });

    // fetch(`${process.env.REACT_APP_API_RECIPIENTS_URL}/add-voucher-to-list`, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify(list.id),
    // })
    //   .then((response: any) => {
    //     if (response.ok) {
    //       dispatchLists({
    //         type: 'DELETE_LIST',
    //         payload: list.listName,
    //       })

    //       toast.success('List created.', {
    //         position: toast.POSITION.BOTTOM_LEFT,
    //       })

    //       router.push('/deals-category?country=ZA&category=Shopping')
    //     }
    //   })
    //   .catch((error: unknown) => {
    //     setMessage('Something went wrong. Please try again later.')
    //   })
  };

  const onDelete = (e) => {
    e.stopPropagation();
    setIsLoading(true);
    // setMessage(null)

    dispatchLists({
      type: 'DELETE_VOUCHER_FROM_LIST',
      payload: { listName: listName, dealId: deal.dealId },
    });

    toast.success(`Voucher ${deal.voucherName} deleted from list.`, {
      position: toast.POSITION.BOTTOM_LEFT,
    });

    // fetch(`${process.env.REACT_APP_API_RECIPIENTS_URL}/delete-list`, {
    //   method: 'DELETE',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify(list.id),
    // })
    //   .then((response: any) => {
    //     if (response.ok) {
    //       dispatchLists({
    //         type: 'DELETE_LIST',
    //         payload: list.listName,
    //       })

    //       toast.success(`Recipient ${deal.voucherName} deleted from list.`, {
    //          position: toast.POSITION.BOTTOM_LEFT,
    //       });

    //       router.push('/deals-category?country=ZA&category=Shopping')
    //     }
    //   })
    //   .catch((error: unknown) => {
    //     setMessage('Something went wrong. Please try again later.')
    //   })
    setIsLoading(false);
  };

  return (
    <div className={styles.cartHolder}>
      {isHistory && (
        <button className={styles.buyNowButton} onClick={() => resendHandler()}>
          Resend
        </button>
      )}
      {deal.status === 'Coming Soon' ? (
        <button className={styles.buyNowButton} disabled>
          Coming soon
        </button>
      ) : deal.stockQuantity === 0 && !deal.categories.includes('Education') ? (
        <button className={styles.cart} disabled>
          Out of stock
        </button>
      ) : (
        <button
          className={styles.buyNowButton}
          onClick={() => addtoCartHandler(deal, redemptionInputValue ? redemptionInputValue : deal.redemptionValues[0])}
        >
          Add to Cart
        </button>
      )}
      {/* <Link
        to="/cart"
        style={{
          marginLeft: "10px",
          color: "#0fbc49",
          textDecoration: "underline",
        }}
      >
        Go to Cart
      </Link> */}
    </div>
  );
}

export default CartButtons;
