import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Related from "../../components/blog/Related";
import Footer from "../../components/Footer";

const Startup302 = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/blog");
  };

  return (
    <div className="min-h-screen">
      <div className="container mx-auto px-4">
        <Header />
        <main>
          <div className="foster-container">
            <div className="button-main2" onClick={handleBack}>
              <img
                src="/img/blog/pages/arrow-left.png"
                alt="Back"
                className="arrow-left"
              />
              <span className="back-text">Back</span>
            </div>

            <div className="image-section2">
              <img
                src="/img/blog/ceo.jpg"
                alt="CEO holding up first place certificate"
                className="community-image"
              />
            </div>

            <div className="content-section2">
              <div className="metadata2">
                <span>News</span>
                <img
                  src="/img/blog/pages/Ellipse.png"
                  alt="Dot"
                  className="dot"
                />
                <span>10min read</span>
                <img
                  src="/img/blog/pages/Ellipse.png"
                  alt="Dot"
                  className="dot"
                />
                <span>May 20, 2024</span>
              </div>

              <h1 className="title2">
                Lipaworld partners with CheckUps to unlock healthcare benefits
                for families of Kenyans back at home
              </h1>

              <p className="description2">
                <br />
                <br /> An innovative digital voucher marketplace, has clinched
                first place in the Fintech Category at the prestigious Startup
                302 pitch competition, coordinated by the Delaware Prosperity
                Partnership (DPP).
                <br />
                <br />
                Hosted by the Delaware Prosperity Partnership, the competition
                reflects Delaware's commitment to fostering a diverse and
                inclusive innovation ecosystem by supporting underrepresented
                technology-enabled startups. Since its inception in 2020, the
                program has been a catalyst for growth, has provided funding to
                37 ventures, and continues attracting high-caliber startups from
                various industries.
                <br />
                <br />
                Jonathan Katende, CEO of Lipaworld said “This recognition at
                Startup 302 is more than just an award – signifies a major
                milestone for Lipaworld. The access to mentorship, funding, and
                networking opportunities provided by Startup302 will propel our
                growth and social impact. We're excited to leverage these
                connections to expand our reach and empower our platform users
                to connect and support their loved ones across borders.
                <br />
                <br />
                This year's competition spotlighted 100 startups in categories
                that mirror Delaware's dynamic innovation landscape, including
                Early Stage, Fintech, Life Sciences, Environmental Impact, and
                Delaware Impact. Lipaworld's victory in the Fintech category
                underscores the company's alignment with Delaware's vision for
                financial innovation and business services.
                <br />
                <br />
                Noah Olson, DPP Director of Innovation, lauds Startup 302's role
                in fortifying the local innovation ecosystem, stating, "We're
                honored to provide entrepreneurs with access to key funding and
                facilitators that can make a difference in their development and
                enable them to realize their potential." Lipaworld's innovative
                approach to financial technology, with its focus on enabling
                seamless and affordable cross-border transactions, resonated
                deeply with the judges. The company's platform facilitates
                secure remittances while introducing a unique voucher system
                that ensures funds are utilized for their intended purposes,
                thereby providing significant value to users by enhancing
                transparency and accountability.
                <br />
                <br />
                Lipaworld extends its gratitude to Delaware Prosperity
                Partnership and all the innovation-supporting partners involved
                in Startup 302, including Bronze Valley Gener8tor, Delaware
                Black Chamber of Commerce, New Castle County Chamber of
                Commerce, Emerging Enterprise Center, The Innovation Space,
                Chesapeake Ag Innovation Center, Delaware Sustainable Chemistry
                Alliance, University of Delaware Horn Entrepreneurship, True
                Access Capital's Women's Business Center, Small Business
                Development Center, Delaware State University College of
                Business, Sara Crawford, and StartOut.
                <br />
                <br />
                For more information about Lipaworld and its innovative
                financial solutions, please visit{" "}
                <a href="https://lipaworld.com" className="underline">
                  www.lipaworld.com
                </a>
                .
              </p>

              <div className="author-section2">
                {/* <img src="/img/blog/rev1.png" alt="Author" className="avatar" /> */}
                <span className="author-name2">Lipaworld</span>
              </div>
            </div>
          </div>
          <Related />
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default Startup302;
