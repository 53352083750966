import React from "react";
import { useNavigate } from 'react-router-dom';
import styles from "./progressbar.module.css";

const ProgressBar = () => {
  const navigate = useNavigate();

  const handleShop = () => {
    navigate('/marketplace')
  };

  return (
    <div className={styles.adjust}>
      <button className={styles.lipaworld_backButton} onClick={handleShop}>
        <svg width="20" height="20" viewBox="0 0 24 24">
          <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
        </svg>
        <span className={styles.backText}>Back to Shop</span>
      </button>
      <div className={styles.progressContainer}>
        <div className={`${styles.step} ${styles.active}`} style={{ borderColor: "#00ae5a" }}>
          <span className={styles.number}>1</span>
          <span className={styles.label}>Cart</span>
        </div>
        <div className={styles.line}></div>
        <div className={styles.step}>
          <span className={styles.numberInactive}>2</span>
          <span className={styles.labelInactive}>Recipient</span>
        </div>
        <div className={styles.line}></div>
        <div className={styles.step}>
          <img
            src="/img/shop/tick.png"
            alt="tick-circle"
            className={styles.icon}
          />
          <span className={styles.labelInactive}>Payment</span>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;