import React from "react";
import Navbar from "../../components/marketplace/nav/nav";
import SecondaryNav from "../../components/marketplace/secnav/secnav";
import ReviewGridView from "../../components/marketplace/review-view/reviewgrid";
import CardsFooter from "../../components/marketplace/footer/footer";

const Review = () => {
    return (
        <>
        <Navbar />
        <SecondaryNav />
        <ReviewGridView />
        <CardsFooter />
        </>
    )
}
export default Review;