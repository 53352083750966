import React from "react";
import Navbar from "../../components/marketplace/nav/nav";
import SecondaryNav from "../../components/marketplace/secnav/secnav";
import ProductGridView from "../../components/marketplace/product-view/productgrid";
import CardsFooter from "../../components/marketplace/footer/footer";

const Product = () => {
    return (
        <>
        <Navbar />
        <SecondaryNav />
        <ProductGridView />
        <CardsFooter />
        </>
    )
}
export default Product;