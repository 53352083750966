import calculatePaymentItemAmount from './calculatePaymentItemAmount';

const mapOrderItems = (cartItems, rate, transaction) => {
  const totalDiscount = transaction?.cartDiscounts || 0;
  let remainingDiscount = totalDiscount;

  const orderItems = cartItems.map((item) => {
    const itemAmount = calculatePaymentItemAmount(item, rate);
    const maxDiscount = Math.min(itemAmount, remainingDiscount);
    const adjustedAmount = itemAmount - maxDiscount;
    remainingDiscount -= maxDiscount;

    return {
      name: item.deal.voucherName,
      quantity: item.quantity,
      unit_amount: {
        currency_code: 'USD',
        value: adjustedAmount,
      },
      description: `dealId:${item.deal.dealId}`,
    };
  });

  const additionalItems = [
    {
      name: 'Admin fee',
      quantity: 1,
      unit_amount: {
        currency_code: 'USD',
        value: transaction?.processingFee ?? 0,
      },
    },
    {
      name: 'Transaction fee',
      quantity: 1,
      unit_amount: {
        currency_code: 'USD',
        value: transaction?.transactionFee ?? 0,
      },
    },
  ];

  return [...orderItems, ...additionalItems];
};

export default mapOrderItems;
