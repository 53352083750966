import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./progressbar3.module.css";

const ProgressBar = () => {
    const navigate = useNavigate();
  
    const handleRecipient = () => {
      navigate('/recipient')
    };
  return (
    <div className={styles.adjust}>
      <button className={styles.lipaworld_backButton} onClick={handleRecipient}>
        <svg width="24" height="24" viewBox="0 0 24 24">
          <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
        </svg>
        Back to Recipient
      </button>
      <div className={styles.progressContainer}>
        <div className={`${styles.step} ${styles.active}`} style={{ borderColor: "#00ae5a" }}>
          <span className={styles.number}>1</span>
          <span className={styles.label}>Cart review</span>
        </div>
        <div className={styles.line1}></div>
        <div className={`${styles.step} ${styles.active}`} style={{ borderColor: "#00ae5a" }}>
          <span className={styles.number}>2</span>
          <span className={styles.label}>Select recipient</span>
        </div>
        <div className={styles.line1}></div>
        <div className={`${styles.steppay} ${styles.active}`} style={{ borderColor: "#602182" }}>
          <img
            src="/img/shop/tick-circle.png"
            alt="tick-circle"
            className={styles.icon}
          />
          <span className={styles.labelInactive}>Payment</span>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
