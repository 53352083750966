import React, { useRef, useState } from 'react';
import VoucherDetails from '../voucher-details/voucherDetails';
import { useNavigate } from 'react-router-dom'
import styles from './ProductGridView.module.css';

const ProductCard = ({ title, price, onClick }) => (
  <div className={styles.lipaworld_productCard} onClick={onClick}>
    <div className={styles.lipaworld_productImage}>
    <img src='/img/shop/yellow.png'/>
    </div>
    <div className={styles.lipaworld_notchContainer}>
      <div className={styles.lipaworld_leftNotch}></div>
      <div className={styles.lipaworld_dottedLine}></div>
      <div className={styles.lipaworld_rightNotch}></div>
    </div>
    <div className={styles.lipaworld_productInfo}>
      <h3 className={styles.lipaworld_productTitle}>{title}</h3>
      <p className={styles.lipaworld_productPrice}>{price}</p>
    </div>
    <button className={styles.lipaworld_addToCartButton}>
      Add to Cart
      <svg width="20" height="20" viewBox="0 0 24 24" className={styles.lipaworld_cartIcon}>
        <path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 3h1l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h11c.55 0 1-.45 1-1s-.45-1-1-1H7l1.1-2h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.67-1.43c-.16-.35-.52-.57-.9-.57H1c-.55 0-1 .45-1 1s.45 1 1 1zm16 15c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"/>
      </svg>
    </button>
  </div>
);

const ProductGridView = ({ onBack }) => {
  const navigate = useNavigate();
  const [modalState, setModalState] = useState({ isOpen: false, selectedProduct: null });
  const products = [
    { id: 1, title: 'MTN Voucher', price: 'NGN 1000' },
    { id: 2, title: 'MTN Voucher', price: 'NGN 1000' },
    { id: 3, title: 'MTN Voucher', price: 'NGN 1000' },
    { id: 4, title: 'MTN Voucher', price: 'NGN 1000' },
    { id: 5, title: 'MTN Voucher', price: 'NGN 1000' },
    { id: 6, title: 'MTN Voucher', price: 'NGN 1000' },
    { id: 7, title: 'MTN Voucher', price: 'NGN 1000' },
    { id: 8, title: 'MTN Voucher', price: 'NGN 1000' },
    { id: 9, title: 'MTN Voucher', price: 'NGN 1000' },
    { id: 10, title: 'MTN Voucher', price: 'NGN 1000' },
  ];

  const handleCardClick = (title, price) => {
    setModalState({ isOpen: true, selectedProduct: { title, price } });
  };

  const handleCloseModal = () => {
    setModalState({ isOpen: false, selectedProduct: null });
  };

  const handleBackClick = () => {
    navigate('/marketplace');
  };

  return (
    <div className={styles.lipaworld_container}>
      <button className={styles.lipaworld_backButton} onClick={handleBackClick}>
        <svg width="24" height="24" viewBox="0 0 24 24">
          <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/>
        </svg>
        Back
      </button>

      <h1 className={styles.lipaworld_title}>Products</h1>

      <div className={styles.lipaworld_productGrid}>
        {products.map(product => (
          <ProductCard key={product.id} {...product} onClick={() => handleCardClick(product.title, product.price)} />
        ))}
      </div>
      {modalState.isOpen && (
        <VoucherDetails 
          onClose={handleCloseModal}
          voucher={modalState.selectedProduct}
        />
      )}
    </div>
  );
};

export default ProductGridView;