import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../styles/search.module.css";

const SearchBar = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/marketplace");
  };

  return (
    <div className={styles.searchBarContainer}>
      <img
        src="/img/shop/close-circle.png"
        alt="Close Icon"
        className={styles.closeIcon}
        onClick={handleBack}
        role="button"
        tabIndex="0"
        onKeyDown={handleBack}
      />
      <input
        type="text"
        placeholder="What are you looking for?"
        className={styles.searchInput}
      />
      <img
        src="/img/shop/search-normal.png"
        alt="Search Icon"
        className={styles.searchIcon}
      />
    </div>
  );
};

const PopularSuggestions = () => {
  const suggestions = [
    "Item Suggestion",
    "Item Suggestion",
    "Item Suggestion",
    "Item Suggestion",
    "Item Suggestion",
    "Item Suggestion",
    "Item Suggestion",
    "Item Suggestion",
  ];

  return (
    <div className={styles.suggestionsContainer}>
      <h2 className={styles.title}>Popular</h2>
      <div className={styles.suggestionGrid}>
        {suggestions.map((suggestion, index) => (
          <div key={index} className={styles.suggestionItem}>
            {suggestion}
          </div>
        ))}
      </div>
    </div>
  );
};

const RecentSearches = () => {
  const items = [
    "Item",
    "Item",
    "Item",
    "Item",
    "Item",
    "Item",
    "Item",
    "Item",
  ];

  return (
    <div className={styles.recentSearchesContainer}>
      <h2 className={styles.title}>Recent searches</h2>
      <ul className={styles.list}>
        {items.map((item, index) => (
          <li key={index} className={styles.listItem}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

const Search = () => {
  return (
    <div>
      <SearchBar />
      <PopularSuggestions />
      <RecentSearches />
    </div>
  );
};

export default Search;
