import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../../../context/CartContext';
import styles from './cartitem.module.css';

const CartSummary = ({ buttonText }) => {
  const navigate = useNavigate();
  const [detailsVisible, setDetailsVisible] = useState(true);
  const { totalAmount, subtotal, currencyRate, transactionFee, processingFee, discounts } = useCart();

  const toggleDetails = () => {
    setDetailsVisible(!detailsVisible);
  };

  const handleCart = () => {
    if (buttonText === 'Continue to Recipient') {
      navigate('/recipient');
    } else if (buttonText === 'Continue to Payment') {
      navigate('/payment');
    }
  };

  const formatAmount = (amount) => {
    return Number(amount).toFixed(2);
  };

  return (
    <div className={styles.cartSummary}>
      <div className={styles.head}>
        <h2 className={styles.summaryTitle}>Cart Summary</h2>
        <button className={styles.toggleDetailsButton} onClick={toggleDetails}>
          {detailsVisible ? 'Hide Details' : 'Show Details'}
        </button>
      </div>
      <div className={styles.couponSection}>
        <input type="text" placeholder="Enter coupon code" className={styles.couponInput} />
        <button className={styles.applyButton}>Apply</button>
      </div>

      {detailsVisible && (
        <div className={styles.summaryDetails}>
          <div className={styles.summaryRow}>
            <span>Subtotal</span>
            <span>USD {subtotal}</span>
          </div>
          {Number(discounts) > 0 && (
            <div className={styles.summaryRow}>
              <span>Discount</span>
              <span>USD {formatAmount(discounts)}</span>
            </div>
          )}
          <div className={styles.summaryRow}>
            <span>Transaction fee</span>
            <span>USD {transactionFee}</span>
          </div>
          <div className={styles.summaryRow}>
            <span>Processing fee</span>
            <span>USD {processingFee}</span>
          </div>
        </div>
      )}

      <div className={`${styles.summaryRow} ${styles.totalRow}`}>
        <span>Total</span>
        <span>USD {totalAmount}</span>
      </div>

      {buttonText ? (
        <button className={`mt-10 ${styles.checkoutButton} ${styles.continueButton}`} onClick={handleCart}>
          {buttonText ? buttonText : 'Continue to Recipient'}
        </button>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CartSummary;
