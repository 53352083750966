import React, { useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import styles from './CreateRecipientModal.module.css';
import { useAuth } from '../../../context';
import countries from '../../../utils/countries';

const CreateRecipientModal = ({ onClose, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { authState } = useAuth();

  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    mobileNumber: '',
    mobileCountryCode: '',
    electricityMeterNumber: '',
    studentNumber: '',
    cowryPhoneNumber: '',
    country: 'NG',
    senderId: authState?.id,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const token = localStorage.getItem('token');
    if (!token || !authState?.id) {
      setError('Authentication required');
      setLoading(false);
      return;
    }

    try {
      const submissionData = {
        ...formData,
        countryCode: formData.mobileCountryCode,
      };

      const response = await fetch(`${process.env.REACT_APP_API_RECIPIENTS_URL}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(submissionData),
      });

      if (response.ok) {
        const responseData = await response.json();
        const newRecipient = {
          id: responseData.id,
          name: formData.name,
          surname: formData.surname,
          email: formData.email,
          mobileNumber: formData.mobileNumber,
          country: formData.country,
          meterNumber: formData.electricityMeterNumber,
          studentNumber: formData.studentNumber,
          dateAdded: new Date().toISOString(),
        };

        setLoading(false);
        if (onSuccess) {
          onSuccess(newRecipient);
        } else {
          onClose();
        }
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Failed to create recipient');
        setLoading(false);
      }
    } catch (err) {
      setError(err.message || 'Failed to create recipient');
      setLoading(false);
    }
  };

  const handlePhoneNumberChange = (value, country) => {
    setFormData((prev) => ({
      ...prev,
      mobileNumber: `+${value}`,
      mobileCountryCode: country?.dialCode ? `+${country.dialCode}` : '',
    }));
  };

  const handleChange = (field) => (e) => {
    setFormData((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <h2>Create Recipient</h2>
          <button className={styles.closeButton} onClick={onClose}>
            x
          </button>
        </div>

        {error && <div className={styles.errorMessage}>{error}</div>}

        <p className={styles.modalSubtext}>Complete the fields to create a recipient</p>

        <form className={styles.createForm} onSubmit={handleSubmit}>
          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label>First name*</label>
              <input type="text" value={formData.name} onChange={handleChange('name')} required />
            </div>

            <div className={styles.formGroup}>
              <label>Last name*</label>
              <input type="text" value={formData.surname} onChange={handleChange('surname')} required />
            </div>
          </div>

          <div className={styles.formGroup}>
            <label>Email</label>
            <input
              type="email"
              placeholder="Enter recipient email"
              value={formData.email}
              onChange={handleChange('email')}
            />
          </div>

          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label>Country*</label>
              <select
                value={formData.country}
                onChange={handleChange('country')}
                required
                className={styles.countrySelect}
              >
                {countries.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>

            <div className={styles.formGroup}>
              <label>Mobile number*</label>
              <PhoneInput
                country={formData.country.toLowerCase()}
                value={formData.mobileNumber}
                onChange={handlePhoneNumberChange}
                required
                enableSearch={true}
                inputProps={{
                  required: true,
                }}
                inputStyle={{
                  width: '100%',
                  height: '40px',
                  fontSize: '16px',
                  borderRadius: '4px',
                }}
                containerStyle={{
                  width: '100%',
                }}
              />
            </div>
          </div>

          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label>Meter number</label>
              <input
                type="text"
                placeholder="Enter recipient meter number"
                value={formData.electricityMeterNumber}
                onChange={handleChange('electricityMeterNumber')}
              />
            </div>

            <div className={styles.formGroup}>
              <label>Student number</label>
              <input
                type="text"
                placeholder="Enter recipient student number"
                value={formData.studentNumber}
                onChange={handleChange('studentNumber')}
              />
            </div>
          </div>

          <div className={styles.formGroup}>
            <label>Cowry phone number (Nigeria only)</label>
            <input
              type="text"
              placeholder="Enter recipient Cowry phone number"
              value={formData.cowryPhoneNumber}
              onChange={handleChange('cowryPhoneNumber')}
            />
          </div>

          <div className={styles.modalActions}>
            <button type="button" className={styles.cancelButton} onClick={onClose} disabled={loading}>
              Cancel
            </button>
            <button type="submit" className={styles.createButton} disabled={loading}>
              {loading ? 'Creating...' : 'Create'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateRecipientModal;
