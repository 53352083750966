import React from "react";
import Navbar from "../../components/marketplace/nav/nav";
import SecondaryNav from "../../components/marketplace/secnav/secnav";
import BrandGridView from "../../components/marketplace/brand-view/brandgrid";
import CardsFooter from "../../components/marketplace/footer/footer";

const Brand = () => {
    return (
        <>
        <Navbar />
        <SecondaryNav />
        <BrandGridView />
        <CardsFooter />
        </>
    )
}
export default Brand;