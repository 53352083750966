import React from "react";
import Navbar from "../components/marketplace/nav/nav";
import ComingSoonComponent from "../components/coming-soon/coming";

const ComingSoon = () => {
  return (
    <>
      <Navbar />
      <ComingSoonComponent />
    </>
  );
};

export default ComingSoon;
