import React from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import Navbar from '../components/marketplace/nav/nav';
import SecondaryNav from '../components/marketplace/secnav/secnav';
import ProductsByCategoryCountry from '../components/marketplace/products/ProductsByCategoryCountry';
// import ProductSection from "../components/marketplace/products/products";
// import RecentSection from "../components/marketplace/recent/recent";
// import BrandsSection from "../components/marketplace/brands/brands";

const Shop = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const category = urlParams.get('category');
  const countryCode = urlParams.get('countryCode');

  const formatCustomAmount = (status) => {
    if (status === false || status === true) {
      return status;
    } else if (status === 'false') {
      return false;
    } else if (status === 'true') {
      return true;
    } else {
      return false;
    }
  };

  const { isLoading, isError, data } = useQuery({
    queryKey: ['products', countryCode, category],
    queryFn: async () => {
      if (countryCode && category) {
        const { data } = await axios.get(
          `${process.env.REACT_APP_DEALS_API_URL}/deals/category?category=${category}&country=${countryCode}`,
        );
        const mappedProducts = data.map((product) => ({
          merchantId: product.merchant_id,
          categories: [product.category],
          merchantName: product.merchant_name,
          countryCode: product.country,
          dealId: product.product_id,
          partnerProductId: product.partner_product_id,
          status: product.status,
          redemptionInput: product.redemption_input,
          redemptionType: product.redemption_type,
          transactionFee: product.transaction_fee,
          customAmount: formatCustomAmount(product.custom_amount),
          minimumAmount: product.minimum_amount ? Number(product.minimum_amount) : 0,
          maximumAmount: product.maximum_amount ? Number(product.maximum_amount) : 0,
          redemptionInstructions: product.redemption_instructions,
          terms: product.terms,
          partnerName: product.channel_partner,
          partnerId: product.partner_id,
          voucherDescription: product.description,
          voucherName: product.product_name,
          voucherImageUrl: product.voucher_image.includes('https')
            ? product.voucher_image
            : product.voucher_image?.length > 0
            ? `${process.env.REACT_APP_IMAGES_BASE_URL}${product.voucher_image}`
            : '',
          redemptionCountryCode: product.country,
          redemptionCurrency: product.redemption_currency
            ? product.redemption_currency
            : product.country === 'ZA'
            ? 'ZAR'
            : product.country === 'ZW' || product.country === 'US'
            ? 'USD'
            : product.country === 'NG'
            ? 'NGN'
            : product.country === 'KE'
            ? 'KES'
            : product.country === 'GH'
            ? 'GHS'
            : '',
          usdAmount:
            (product.channel_partner === 'Lipaworld' && !product.zar) ||
            product.channel_partner === 'NABU' ||
            product.channel_partner === 'Checkups COVA' ||
            product.channel_partner === 'TAP' ||
            product.channel_partner === 'DT One' ||
            product.channel_partner === 'Prepay Nation' ||
            product.country === 'US' ||
            (product.country === 'ZW' && product.usd_amount && !product.custom_amount)
              ? Number(product?.usd_amount)
              : null,
          redemptionValues:
            product.country === 'ZA' && product.zar ? [product.zar * 100] : [product.redemption_amount * 100],
          retailStatus: product.status,
          subCategories: [],
          stockQuantity: product.quantity ?? null,
        }));
        return mappedProducts;
      }
    },
  });

  return (
    <>
      <Navbar />
      <SecondaryNav />
      {data && data.length > 0 && <ProductsByCategoryCountry data={data} />}
    </>
  );
};

export default Shop;
