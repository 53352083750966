import React, { useState } from "react";
import Navbar from "../../components/marketplace/nav/nav";
import AccountOverviewComponent from "../../components/profile/overview/overview";
import CardsFooter from "../../components/marketplace/footer/footer";

const AccountOverview = () => {
  return (
    <>
    <Navbar />
    <AccountOverviewComponent />
    <CardsFooter />
    </> 
  )
}

export default AccountOverview;