import React from "react";

const SendValue = () => {
  return (
    <section className="py-12 sendsec">
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-stretch">
          <div
            className="w-full md:pl-8 plmob text-center"
            data-aos="fade-left"
          >
            <h2 className="text-3xl font-bold mb-4 send">
              Send value back to Africa
            </h2>
            <p className="mb-4 sendp">
              You can also use Lipaworld to pay for electricity bills, in South
              Africa, Kenya, Nigeria, and Zimbabwe, keeping households powered
              and connected. Help cover important needs like school fees across
              Africa, including South Africa, or send thoughtful gift vouchers
              to show your care.
            </p>
          </div>

          <div
            className="w-full flex justify-center mb-4"
            data-aos="fade-right"
          >
            <img
              src="/img/countries.jpg"
              alt="Lipaworld countries"
              className="rounded-lg purp w-full max-w-[750px]"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SendValue;
