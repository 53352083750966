export const Lists = [
  {
    id: "wfe23-4wefwe23-wefwef234",
    listName: "list1",
    listType: "Individual",
    access: "Can edit",
    keepPurchased: true,
    recipientId: "b141b922-b7ba-4fe5-8031-ca5079b85280",
    userId: "b141b922-b7ba-4fe5-8031-ca5079b85280",
    buyers: ["4444-rt56-rt54-45rt"],
    items: [],
    linkString: "weflist1",
  },
  {
    id: "wef234-234wef-wef234-wefwe23",
    listName: "list2",
    listType: "Organization",
    access: "View only",
    keepPurchased: false,
    recipientId: "ba537834-673c-402d-8994-80cbd06c6f76",
    userId: "ba537834-673c-402d-8994-80cbd06c6f76",
    buyers: ["ppe5-3445-rt54-45rt"],
    items: [],
    linkString: "wef234-list2",
  },
];
