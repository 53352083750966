import React from "react";
import { MenuItem, Select } from "@mui/material";
import { countryAssets } from "../../utils/banners";
import { useSelectedCountry } from "../../context/SelectedCountryContext";

const CountryPicker = () => {
  const { selectedCountry, setSelectedCountry } = useSelectedCountry();

  const handleChange = (event) => {
    const selectedCountryCode = event.target.value;
    const selectedCountry = countryAssets.find(
      (country) => country.countryCode === selectedCountryCode
    );
    setSelectedCountry(selectedCountry);
  };

  return (
    <Select
      value={selectedCountry.countryCode}
      onChange={handleChange}
      style={{ display: "flex", outline: "none" }}
      renderValue={(selected) => {
        const country = countryAssets.find((c) => c.countryCode === selected);
        return (
          <>
            <img
              src={country.flag}
              alt={country.name}
              style={{
                height: "20px",
                width: "auto",
                marginRight: "8px",
                borderRadius: "50%",
              }}
            />
            {country.countryCode}
          </>
        );
      }}
    >
      {countryAssets.map((country) => (
        <MenuItem key={country.name} value={country.countryCode}>
          <img
            src={country.flag}
            alt={country.name}
            style={{
              height: "20px",
              width: "auto",
              marginRight: "8px",
              borderRadius: "50%",
            }}
          />
          {country.countryCode}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CountryPicker;
