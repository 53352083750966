const calculateProcessingFee = (
  subTotalAmount,
  totalTransactionFee,
  cartItems
) => {
  const excludedProcessingFeeTotal = cartItems
    .filter(
      (item) =>
        item.deal.redemptionType === "DONATION" &&
        item.includeProcessingFee === false &&
        item.includeProcessingFee !== undefined
    )
    .reduce((acc, item) => acc + item.deal.redemptionValues[0] / 100, 0);

  const rawProcessingFee =
    (subTotalAmount + totalTransactionFee - excludedProcessingFeeTotal) * 0.025;
  const processingFee = (
    rawProcessingFee > 0 ? rawProcessingFee + 0.3 : rawProcessingFee
  ).toFixed(2);
  return processingFee;
};

export default calculateProcessingFee;
