import React, { useState, useEffect } from "react";
import Sidebar from "../sideBar";
import styles from "./contact.module.css";
import faqData from "../../../utils/faqs";
import { Loader2 } from "lucide-react";
import { useLocation } from "react-router-dom";

const ContactUs = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(() => {
    const params = new URLSearchParams(location.search);
    return params.get("selected-tab") === "faqs" ? "FAQs" : "Contact-details";
  });
  const [isLoading, setIsLoading] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleQuestion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const TIMEOUT_DURATION = 30000;

  const resetForm = (form) => {
    form.reset();
    setIsLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setSubmitStatus(null);

    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => {
        reject(new Error("Request timed out"));
      }, TIMEOUT_DURATION);
    });

    try {
      const formData = {
        contactType: "INDIVIDUAL",
        firstName: e.target.elements.firstName.value,
        lastName: e.target.elements.lastName.value,
        emailAddress: e.target.elements.email.value,
        // phoneNumber: e.target.elements.phone?.value || "",
        subject: e.target.elements.subject.value,
        message: e.target.elements.message.value,
      };

      const response = await Promise.race([
        fetch(`${process.env.REACT_APP_API_USERS_URL}/contact-us`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }),
        timeoutPromise,
      ]);

      if (!response.ok) {
        throw new Error("Failed to send the message");
      }

      setSubmitStatus("success");
      resetForm(e.target);

      setTimeout(() => {
        setSubmitStatus(null);
      }, 5000);
    } catch (error) {
      // console.error("Error submitting form:", error);
      setSubmitStatus(
        error.message === "Request timed out" ? "timeout" : "error"
      );
      setIsLoading(false);

      setTimeout(() => {
        setSubmitStatus(null);
      }, 5000);
    }
  };

  const getStatusMessage = (status) => {
    switch (status) {
      case "success":
        return "Message sent successfully! We will get back to you soon.";
      case "timeout":
        return "Request timed out. Please check your internet connection and try again.";
      case "error":
        return "Error sending message. Please try again.";
      default:
        return "";
    }
  };

  return (
    <>
      <div className={styles.tabsContainer}>
        <button
          className={`${styles.tab} ${
            activeTab === "Contact-details" ? styles.activeTab : ""
          }`}
          onClick={() => setActiveTab("Contact-details")}
        >
          Contact Details
        </button>
        <button
          className={`${styles.tab} ${
            activeTab === "FAQs" ? styles.activeTab : ""
          }`}
          onClick={() => setActiveTab("FAQs")}
        >
          FAQs
        </button>
      </div>
      {activeTab === "Contact-details" && (
        <div className="contact-section">
          <div className="contact-form">
            {submitStatus && (
              <div
                className={`p-4 mb-4 rounded-lg ${
                  submitStatus === "success"
                    ? "bg-green-50 border border-green-200 text-green-700"
                    : "bg-red-50 border border-red-200 text-red-700"
                }`}
              >
                {getStatusMessage(submitStatus)}
              </div>
            )}

            <h1 className="form-title">Message Us</h1>
            <p className="form-subtitle">
              We&apos;ll get back to you within 24 Hours. For support queries
              please use the chat functionality.
            </p>
            <form className="form" onSubmit={handleSubmit}>
              <div className="form-groupname">
                <div
                  className="form-group"
                  style={{ flex: 1, marginRight: "8px" }}
                >
                  <label>First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    placeholder="Newman"
                    required
                    disabled={isLoading}
                  />
                </div>
                <div
                  className="form-group"
                  style={{ flex: 1, marginLeft: "8px" }}
                >
                  <label>Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Placeholder"
                    required
                    disabled={isLoading}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Email Address</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter your email address"
                  required
                  disabled={isLoading}
                />
              </div>
              {/* <div className="form-group">
                      <label>Phone number (Optional)</label>
                      <input
                          type="tel"
                          name="phone"
                          placeholder="Enter your phone number"
                          disabled={isLoading} />
                  </div> */}
              <div className="form-group">
                <label>Subject</label>
                <input
                  type="text"
                  name="subject"
                  placeholder="Enter your subject"
                  required
                  disabled={isLoading}
                />
              </div>
              <div className="form-group">
                <label>Message</label>
                <textarea
                  name="message"
                  placeholder="Write your message"
                  required
                  disabled={isLoading}
                ></textarea>
              </div>
              <button
                type="submit"
                className="submit-button flex items-center justify-center"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Sending...
                  </>
                ) : (
                  "Send message"
                )}
              </button>
            </form>
          </div>

          <div className="contact-info">
            <div>
              <h2>Email Us</h2>
              <p>
                For general inquiries, partnership opportunities, or customer
                support.
              </p>
              <a href="mailto:support@lipaworld.com">support@lipaworld.com</a>
            </div>
            <div>
              <h2>Our Office</h2>
              <p>For any mail or correspondence.</p>
              <p>
                <span className="color">
                  254 Chapman Rd, Ste 208 #12376 Newark, Delaware 19702, USA
                </span>
              </p>
            </div>
            <div>
              <h2>Follow Us On Social Media</h2>
              <p>
                Stay connected with the Lipaworld community on social media for
                updates, offers and more.
              </p>
              <div className="social-media">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://instagram.com/lipaworldafrica/"
                >
                  Instagram
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/LipaworldAfrica"
                >
                  X
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://web.facebook.com/profile.php?id=100093207047292"
                >
                  Facebook
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://linkedin.com/company/lipaworld"
                >
                  LinkedIn
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.youtube.com/@Lipaworldafrica"
                >
                  YouTube
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
      {activeTab === "FAQs" && (
        <div className="faq-questions-container">
          <div className="faq-questions">
            {faqData.map((category, catIndex) =>
              category.questions.map((question, qIndex) => (
                <div key={`${catIndex}-${qIndex}`}>
                  <div
                    className="faq-question"
                    onClick={() => toggleQuestion(`${catIndex}-${qIndex}`)}
                  >
                    <div className="faq-question-summary">
                      {question.summary}
                    </div>
                    <img
                      src="/img/faqs/chevron-down.svg"
                      alt="Expand"
                      style={{
                        transform:
                          activeIndex === `${catIndex}-${qIndex}`
                            ? "rotate(180deg)"
                            : "rotate(0)",
                        transition: "transform 0.3s",
                      }}
                    />
                  </div>
                  {activeIndex === `${catIndex}-${qIndex}` && (
                    <div className="faq-question-details">
                      {question.details}
                    </div>
                  )}
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </>
  );
};

const Contact = () => {
  return (
    <div className={styles.container}>
      <Sidebar />
      <main className={styles.mainContent}>
        <h1 className={styles.h1}>Contact Us</h1>
        <div className={styles.grid}>
          <ContactUs />
        </div>
      </main>
    </div>
  );
};

export default Contact;
