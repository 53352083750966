import calculateRedemptionAmount from './calculateRedemptionAmount';

const itemAmount = (item, rate) => {
  if (item.deal?.usdAmount) {
    return item.deal.usdAmount;
  }

  const redemptionAmount = calculateRedemptionAmount(item);
  const amount = redemptionAmount / 100 / rate;

  return Number(amount.toFixed(2));
};

export default itemAmount;
