import React from "react";

const Wallet = () => {
  return (
    <section className="py-12 sendsec">
      <div className="container containerm mx-auto px-4">
        <div className="flex flex-col-reverse md:flex-row-reverse items-center">
          <div
            className="md:w-1/2 mb-4 md:mb-0 sendmob mb4mob flex justify-center "
            data-aos="fade-right"
          >
            <img
              src="/img/wallet-preview.png"
              alt="Lipaworld wallet"
              className="rounded-lg purp max-w-[400px]"
            />
          </div>
          <div
            className="md:w-1/2 md:pl-8 plmob text-center-mobile"
            data-aos="fade-left"
          >
            <div className="tagwallet mb-4" data-aos="fade-left">
              Coming Soon!!!
            </div>
            <h2 className="text-3xl font-bold mb-4 send">
              Our Stablecoin wallet
            </h2>
            <p className="mb-4 sendp">
              Lipaworld's stablecoin wallet empowers you to send money instantly
              across wallets and convert it into local currency for seamless
              cross-border transactions. Support loved ones or colleagues in
              Africa by using your wallet to purchase essential goods and
              services on the marketplace for South Africa, Kenya, Nigeria and
              Zimbabwe, or by simply sending Instant money transfers to Kenya,
              Ghana, Nigeria, South Africa and Zimbabwe.
            </p>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSfV3yVqbi-mTCriuNTjCQRGh4Qt_hYgDdj5kAXGxbABN0s-xQ/viewform"
              target="_blank"
              rel="noopener noreferrer"
              className="mt-4 inline-block px-6 py-2 border border-green-600 rounded-lg text-green-600 font-semibold hover:bg-green-50 transition duration-300"
            >
              Join the waitlist
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Wallet;
