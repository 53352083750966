import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { countryAssets } from "../utils/banners";

// interface SelectedCountryContextProps {
//   selectedCountry: (typeof countryAssets)[0];
//   setSelectedCountry: (country: (typeof countryAssets)[0]) => void;
// }

const SelectedCountryContext = createContext(undefined);

export const SelectedCountryProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedCountry, setSelectedCountry] = useState(countryAssets[0]);

  useEffect(() => {
    const currentUrlParams = new URLSearchParams(location.search);
    const countryParam = currentUrlParams.get("country");

    if (countryParam) {
      const countryFromUrl = countryAssets.find(
        (country) => country.countryCode === countryParam
      );
      if (countryFromUrl) {
        setSelectedCountry(countryFromUrl);
        localStorage.setItem("selectedCountry", countryParam);
      }
    } else {
      const storedCountry = localStorage.getItem("selectedCountry");
      if (storedCountry) {
        const countryFromStorage = countryAssets.find(
          (country) => country.countryCode === storedCountry
        );
        if (countryFromStorage) {
          setSelectedCountry(countryFromStorage);
        }
      } else {
        setSelectedCountry(countryAssets[0]);
      }
    }
  }, [location.search]);

  const updateCountry = (country) => {
    setSelectedCountry(country);
    const currentUrlParams = new URLSearchParams(location.search);
    currentUrlParams.set("country", country.countryCode);
    navigate({
      pathname: location.pathname,
      search: `?${currentUrlParams.toString()}`,
    });
    localStorage.setItem("selectedCountry", country.countryCode);
  };

  return (
    <SelectedCountryContext.Provider
      value={{ selectedCountry, setSelectedCountry: updateCountry }}
    >
      {children}
    </SelectedCountryContext.Provider>
  );
};

export const useSelectedCountry = () => {
  const context = useContext(SelectedCountryContext);
  if (!context) {
    throw new Error(
      "useSelectedCountry must be used within a SelectedCountryProvider"
    );
  }
  return context;
};
