import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Features from "../components/Features";
import Partners from "../components/Partners";
import HowItWorks from "../components/HowItWorks";
import Testimonials from "../components/Testimonials";
import FAQ from "../components/Faqs";
import Footer from "../components/Footer";
import Recognition from "../components/recognition";
import SendHolidayGifts from "../components/Send";
import Wallet from "../components/Wallet";
import GetStarted from "../components/GetStarted";
import Explore from "../components/partners/Explore";
import SendValue from "../components/SendValue";

const LandingPage = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: window.location.title,
    });
  }, []);

  return (
    <div className="min-h-screen">
      <div className="container mx-auto px-4">
        <Header />
        <main>
          <Hero />
          <Recognition />
          <Features />
          <Explore />
          <SendHolidayGifts />
          <Wallet />
          <SendValue />
          <Partners />
          <HowItWorks />
          <Testimonials />
          <FAQ />
          <GetStarted />
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;
