import React, { useState, useRef, useEffect } from "react";
import VoucherDetails from "../voucher-details/voucherDetails";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { Link } from "react-router-dom";
import styles from "./ProductSection.module.css";

const ProductCard = ({ title, price, onClick, voucherImageUrl }) => (
  <div className={styles.lipaworld_productCard} onClick={onClick}>
    <div className={styles.lipaworld_productImage}>
      <img src={voucherImageUrl} />
    </div>
    <div className={styles.lipaworld_notchContainer}>
      <div className={styles.lipaworld_leftNotch}></div>
      <div className={styles.lipaworld_dottedLine}></div>
      <div className={styles.lipaworld_rightNotch}></div>
    </div>
    <div className={styles.lipaworld_productInfo}>
      <h3 className={styles.lipaworld_productTitle}>{title}</h3>
      <p className={styles.lipaworld_productPrice}>{price}</p>
    </div>
    <button className={styles.lipaworld_addToCartButton}>
      Add to Cart
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        className={styles.lipaworld_cartIcon}
      >
        <path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 3h1l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h11c.55 0 1-.45 1-1s-.45-1-1-1H7l1.1-2h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.67-1.43c-.16-.35-.52-.57-.9-.57H1c-.55 0-1 .45-1 1s.45 1 1 1zm16 15c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z" />
      </svg>
    </button>
  </div>
);

const ProductsByCategoryCountry = ({ data }) => {
  const isMobile = useIsMobile();
  const scrollContainerRef = useRef(null);

  const [filteredDeals, setFilteredDeals] = useState([]);
  const [headingPlaceholder, setHeadingPlaceholder] = useState("");
  const [showScrollButtons, setShowScrollButtons] = useState(false);
  const [countryCode, setCountryCode] = useState(null);
  const [category, setCategory] = useState(null);
  const [modalState, setModalState] = useState({
    isOpen: false,
    selectedProduct: null,
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const category = urlParams.get("category");
    const code = urlParams.get("countryCode");
    setCountryCode(code);
    setCategory(category);
  }, []);

  useEffect(() => {
    if (filteredDeals && isMobile && filteredDeals.length > 2) {
      setShowScrollButtons(true);
    } else if (filteredDeals && !isMobile && filteredDeals.length > 4) {
      setShowScrollButtons(true);
    }
  }, [filteredDeals]);

  const checkScroll = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } =
        scrollContainerRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth - 10);
    }
  };

  const scroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = direction === "left" ? -320 : 320;
      scrollContainerRef.current.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const handleCardClick = (product) => {
    setModalState({ isOpen: true, selectedProduct: product });
  };

  const handleCloseModal = () => {
    setModalState({ isOpen: false, selectedProduct: null });
  };

  return (
    <section className={styles.lipaworld_section}>
      <div className={styles.lipaworld_header}>
        <div className={styles.lipaworld_titleContainer}>
          <h2 className={styles.lipaworld_sectionTitle}>
            {headingPlaceholder}
          </h2>
          {showScrollButtons && (
            <Link
              to={`/shop?category=${category}&countryCode=${countryCode}`}
              className={styles.lipaworld_seeAllLink}
            >
              See all
            </Link>
          )}
        </div>
        {showScrollButtons && (
          <div className={styles.lipaworld_navigation}>
            <button
              className={`${styles.lipaworld_navButton} ${
                !canScrollLeft ? styles.lipaworld_navButtonDisabled : ""
              }`}
              onClick={() => scroll("left")}
              disabled={!canScrollLeft}
            >
              <svg width="24" height="24" viewBox="0 0 24 24">
                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
              </svg>
            </button>
            <button
              className={`${styles.lipaworld_navButton} ${
                !canScrollRight ? styles.lipaworld_navButtonDisabled : ""
              }`}
              onClick={() => scroll("right")}
              disabled={!canScrollRight}
            >
              <svg width="24" height="24" viewBox="0 0 24 24">
                <path d="M8.59 16.59L10 18l6-6-6-6-1.41 1.41L13.17 12z" />
              </svg>
            </button>
          </div>
        )}
      </div>

      <div
        className={styles.lipaworld_productsContainerColumn}
        ref={scrollContainerRef}
        onScroll={checkScroll}
      >
        {data.map((product) => (
          <ProductCard
            key={product.dealId}
            title={product.voucherName}
            price={`${product.redemptionCurrency} ${product.redemptionValues[0]}`}
            voucherImageUrl={product.voucherImageUrl}
            onClick={() => handleCardClick(product)}
          />
        ))}
      </div>

      {modalState.isOpen && (
        <VoucherDetails
          onClose={handleCloseModal}
          product={modalState.selectedProduct}
        />
      )}
    </section>
  );
};

export default ProductsByCategoryCountry;
