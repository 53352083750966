import React from 'react';
import styles from './footer.module.css';
import { Link } from 'react-router-dom';

const CardsFooter = () => {
  return (
    <div className={styles.foot}>
      <footer className={styles.footer}>
        <img src='/img/shop/mastercard.svg' alt="Mastercard payment method" />
        <img src='/img/shop/visa.svg' alt="Visa payment method" />
        <img src='/img/shop/Paypal.svg' alt="PayPal payment method" />
        <img src='/img/shop/Venmo.svg' alt="Venmo payment method" />
      </footer>
      <div className={styles.disclosure}>
          Lipaworld Corp, a Delaware corporation, operates as an agent of the
          payee. Our services are subject to the terms and conditions outlined
          in our user agreements. Lipaworld is not a bank, and funds held in
          connection with our services are not insured by the FDIC. Please
          review our{' '}
          <a
            href="/privacy-policy"
            className="underline"
          >
            Privacy Policy
          </a>{' '}
          and{' '}
          <a
            href="/terms-of-use"
            className="underline"
          >
            Terms of Service
          </a>{' '}
          for more information.
      </div>
    </div>
  );
};

export default CardsFooter;
