export const countryAssets = [
  {
    name: 'South Africa',
    countryCode: 'ZA',
    flag: '/flags/ZA.svg',
    banners: [
      {
        image: 'https://da0das4tnanmm.cloudfront.net/banners/nabu-banner.webp',
        link: '/deals-category?country=ZA&category=Donations',
      },
      { image: 'https://da0das4tnanmm.cloudfront.net/banners/ZA.webp', link: '' },
    ],
  },
  {
    name: 'Zimbabwe',
    countryCode: 'ZW',
    flag: '/flags/ZW.svg',
    banners: [{ image: 'https://da0das4tnanmm.cloudfront.net/banners/ZW.webp', link: '' }],
  },
  {
    name: 'Nigeria',
    countryCode: 'NG',
    flag: '/flags/NG.svg',
    banners: [{ image: 'https://da0das4tnanmm.cloudfront.net/banners/NG.webp', link: '' }],
  },
  {
    name: 'Kenya',
    countryCode: 'KE',
    flag: '/flags/KE.svg',
    banners: [{ image: 'https://da0das4tnanmm.cloudfront.net/banners/KE.webp', link: '' }],
  },
  {
    name: 'Ghana',
    countryCode: 'GH',
    flag: '/flags/GH.svg',
    banners: [{ image: 'https://da0das4tnanmm.cloudfront.net/banners/GH.webp', link: '' }],
  },
  {
    name: 'United States',
    countryCode: 'US',
    flag: '/flags/US.svg',
    banners: [{ image: 'https://da0das4tnanmm.cloudfront.net/banners/US.webp', link: '' }],
  },
];
