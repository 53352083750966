import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import styles from '../styles/addPhoneNumber.module.css';
import toast, { Toaster } from 'react-hot-toast';

const AddPhoneNumber = () => {
  const { authState, updateAuthState, addPhoneNumber } = useAuth();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneCountry, setPhoneCountry] = useState(null);
  const [consent, setConsent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const source = searchParams.get('source');

    if (!authState.email || !authState.isVerified) {
      if (source !== 'profile') {
        navigate('/signup');
      }
    }
  }, [authState, navigate, location]);

  const handlePhoneNumberChange = (value, countryData) => {
    setPhoneNumber(value);
    setPhoneCountry(countryData);
  };

  const handleConsent = () => {
    setConsent(!consent);
  };

  const handleLogin = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_USERS_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: authState.email,
          password: authState.tempPassword,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Login failed');
      }

      updateAuthState({
        isAuthenticated: true,
        id: data.user.id,
        email: data.user.email,
        firstName: data.user.firstName,
        lastName: data.user.lastName,
        phoneNumber: data.user.phoneNumber,
        country: phoneCountry ? phoneCountry.name : null,
        userType: data.user.userType,
        indexNumber: data.user.indexNumber,
        createdAt: data.user.createdAt,
        updatedAt: data.user.updatedAt,
      });

      localStorage.setItem('token', data.token);
      navigate('/marketplace');
    } catch (error) {
      toast.error(error.message || 'Failed to login');
      navigate('/signin');
    }
  };

  const handleSubmit = async () => {
    if (!phoneNumber) {
      toast.error('Please enter a valid phone number');
      return;
    }

    setIsLoading(true);

    try {
      const formattedPhoneNumber = `+${phoneNumber}`;

      const response = await fetch(`${process.env.REACT_APP_API_USERS_URL}/update-phone`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          phoneNumber: formattedPhoneNumber,
          email: authState.email,
          country: phoneCountry ? phoneCountry.name : null,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Something went wrong');
      }

      if (response.ok) {
        addPhoneNumber(formattedPhoneNumber);
      }

      toast.success(data.message || 'Phone number added successfully');
      await handleLogin();
    } catch (error) {
      toast.error(error.message || 'Failed to add phone number');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <LogoComponent /> <br /> <br /> <br /> <br /> <br />
      <EmailDisplay email={authState.email} />
      <h2 className={styles.title}>Add Your Phone Number</h2>
      <p className={styles.subtitle}>Enter your mobile number to verify</p> <br />
      <div className={styles.inputGroup}>
        <PhoneNumberInput phoneNumber={phoneNumber} onChange={handlePhoneNumberChange} />
      </div>{' '}
      <br /> <br />
      <ConsentCheckbox consent={consent} onConsentChange={handleConsent} /> <br />
      <ContinueButton
        onClick={handleSubmit}
        isEnabled={consent && !isLoading && phoneNumber.length > 0}
        isLoading={isLoading}
      />
    </div>
  );
};

const LogoComponent = () => {
  return (
    <div className={styles.logoContainer}>
      <img src="/img/auth/logo-black2.png" alt="Company Logo" className={styles.logoImage} />
    </div>
  );
};

const EmailDisplay = ({ email }) => {
  return (
    <div className={styles.emailContainer}>
      <span className={styles.loggedInText}>Logged in to</span>
      <span className={styles.email}>{email}</span>
    </div>
  );
};

const PhoneNumberInput = ({ phoneNumber, onChange }) => {
  return (
    <div className={styles.inputWrapper}>
      <label className={styles.label}>Phone Number</label>
      <PhoneInput
        country={'us'}
        value={phoneNumber}
        onChange={onChange}
        enableSearch={true}
        inputStyle={{
          width: '100%',
          height: '40px',
          fontSize: '16px',
          borderRadius: '4px',
        }}
        containerStyle={{
          width: '100%',
        }}
      />
    </div>
  );
};

const ConsentCheckbox = ({ consent, onConsentChange }) => {
  return (
    <div className={styles.consentContainer}>
      <div className={styles.checkboxWrapper}>
        <input type="checkbox" checked={consent} onChange={onConsentChange} className={styles.hiddenCheckbox} />
        <div className={styles.styledCheckbox}>
          {consent && <img src="/img/auth/check.png" alt="checkmark" className={styles.checkboxIcon} />}
        </div>
      </div>
      <span className={styles.consentText}>I consent to receive text messages from Lipaworld</span>
    </div>
  );
};

const ContinueButton = ({ onClick, isEnabled, isLoading }) => (
  <button
    className={`${styles.button} ${isEnabled ? styles.buttonActive : ''}`}
    onClick={onClick}
    disabled={!isEnabled || isLoading}
  >
    {isLoading ? 'Checking...' : 'Continue'}
  </button>
);

export default AddPhoneNumber;
